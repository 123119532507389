/* background-image: url("../../public/images/bg.webp"); */
/* background-image: url("../../public/images/bg.webp"); */
/*-------------------------------------------------------------- 
# Header Start css 
--------------------------------------------------------------*/
header.header {
   background-color: rgba(61, 160, 216, 0.95);
   padding: 17.5px 0px;
   position: sticky;
   top: 0px;
   z-index: 99;
}
.logo a, .logo a img {
   display: block;
   height: 63px;
   object-fit: contain;
   width: 100%;
   max-width: 100%;
}
.header .container {
   display: flex;
   justify-content: space-between;
   align-items: center;
}
.header_navbar {
   display: flex;
   grid-gap: 20px 43px;
   align-items: center;
}
ul.header_menu li a, ul.login_box li a {
   color: var(--white);
   font-size: var(--fs18);
   font-weight: 500;
   line-height: 24px;
}
ul.header_menu li a.active, .login_box li a {
   font-weight: 700;
}
ul.login_box li a {
  font-weight: 700;
}
ul.header_menu, ul.login_box {
   margin: 0;
   padding: 0;
   display: flex;
   align-items: center;
   list-style: none;
   grid-gap: 30px;
}
ul.login_box {
   grid-gap: 20px 48px;
}
.login_box li a.language {
   display: flex;
   align-items: center;
   grid-gap: 10px;
}
.login_box li a.language img {
   width: 23px;
   height: 23px;
}
.hero_img {
   display: block;
   transform: translateY(75px);
   width: 594.42px;
}
.logo img {
   height: 63px;
   position: relative;
   z-index: 99;
}
.hero_img img {
   height: 669.06px;
}
section {
   position: relative;
}
.login_box li a.language select {
  border: unset;
  background-color: unset;
  padding: 2px 4px;
  width: fit-content;
  display: block;
  appearance: none;
  color: var(--white);
  font-size: var(--fs18);
  font-weight: 700;
  line-height: 24px;
  outline: unset;
  cursor: pointer;
/*    text-align: right;*/
}
.login_box li a.language select option {
  color: var(--blue);
}
/*----------------|| Header Menu Css Start ||-----------------------*/
.toggle_btn {
   display: none;
}
.ham {
   cursor: pointer;
   -webkit-tap-highlight-color: transparent;
   transition: transform 400ms;
   -moz-user-select: none;
   -webkit-user-select: none;
   -ms-user-select: none;
   user-select: none;
   max-width: 48px;
   display: block;
}
.hamRotate.active {
   transform: rotate(45deg);
}
.hamRotate180.active {
   transform: rotate(180deg);
}
.line {
   fill: none;
   transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
   stroke: var(--white);
   stroke-width: 3px;
   stroke-linecap: round;
}
header.main_header.show .line {
   stroke: var(--white);
}
.ham7 .top {
   stroke-dasharray: 40 82;
}
.ham7 .middle {
   stroke-dasharray: 40 111;
}
.ham7 .bottom {
   stroke-dasharray: 40 161;
}
.ham7.active .top {
   stroke-dasharray: 17 82;
   stroke-dashoffset: -62px;
}
.ham7.active .middle {
   stroke-dashoffset: 23px;
}
.ham7.active .bottom {
   stroke-dashoffset: -83px;
}
/*-----------------|| Header Menu Css End ||-----------------------*/
/*-------------------------------------------------------------- 
# Home Start css 
--------------------------------------------------------------*/
.hero {
   background-repeat: no-repeat;
   background-size: cover;
   background-position: bottom center;
   position: relative;
   z-index: 3;
   width: 100%;
   min-height: 675px;
}
.hero::before {
   content: "";
   background-image: url("../../public/images/bg.webp");
   background-repeat: no-repeat;
   background-size: cover;
   background-position: bottom center;
   display: block;
   position: absolute;
   top: 0;
   width: 100%;
   z-index: -1;
   bottom: 0;
}
.hero .container {
   display: flex;
   grid-gap: 24px;
   flex-flow: nowrap;
   justify-content: space-between;
}
.hero_inner {
   padding: 68px 0px;
   max-width: 645px;
}
.hero_inner h1 {
   margin: 0px 0px 20px 0px;
   font-size: var(--fs70);
   line-height: 79.2px;
   letter-spacing: -0.5px;
   color: var(--white);
}
.hero_inner p {
   font-size: var(--fs18);
   line-height: 26px;
   font-weight: 500;
   color: var(--white);
   margin: 0px;
}
.app_store {
   display: flex;
   grid-gap: 24px;
   align-items: center;
   margin-top: 40px;
}
.app_store a img {
   height: 62px;
   display: block;
}
.banner_img_box img {
   height: 669.06px;
   width: 574.35px;
   display: block;
}
/*=========================|| Hero Banner Css End ||=============================*/
/*=====================|| Partners Company Css Start ||==========================*/
.partner .item img {
   height: 52px;
   object-fit: contain;
   display: block;
}
/*=====================|| Partners Company Css End ||============================*/
/*======================|| Home Service Css Start ||=============================*/
.service {
   background-color: var(--light);
   background-color: rgba(61, 160, 216, 2%);
}
.service .sec_head {
   align-items: center;
}
.sec_head h2 {
   margin: 0px;
   font-size: var(--fs48);
   font-weight: 700;
   line-height: 42px;
   letter-spacing: -0.5px;
   margin-bottom: 20px;
   text-align: center;
}
.sec_head p {
   font-size: var(--fs18);
   line-height: 28px;
   font-weight: 500;
   text-align: center;
   color: rgba(0, 11, 51, 80%);
}
.sec_head h2 span {
   color: var(--sky_blue);
   display: inline-block;
}
.service_inner {
   display: flex;
   flex-flow: wrap;
   grid-gap: 20px;
}
.service_inner>div.service_box {
   width: calc(100%/4 - 15px);
}
.service_box img {
   margin: 0 auto;
   height: 116px;
   object-fit: contain;
}
.service_box {
   display: flex;
   flex-flow: column;
   grid-gap: 24px;
   padding: 30px;
   background-color: var(--white);
   border-radius: 10px;
   box-shadow: 0px 4px 40px 0px #2B59FF14;
}
.service_content_box {
   display: flex;
   flex-direction: column;
   grid-gap: 6px;
   text-align: center;
}
.service_content_box h3 {
   font-size: var(--fs20);
   line-height: 26px;
   color: var(--blue);
}
.service_content_box p {
   font-size: var(--fs16);
   line-height: 26px;
   font-weight: 400;
   color: rgba(0, 11, 51, 70%);
}
.coming .container .offer_content .sec_head p {
  color: rgba(0, 11, 51, 80%);
}
.offer_content h2, .offer_content p, .about_inner h2, .about_inner p {
   text-align: left;
}
.about .about_inner .sec_head p {
  color: rgba(0, 11, 51, 70%);
}
/*=======================|| Home Service Css End ||=============================*/
/*======================|| Coming Offers Css Start ||===========================*/
.coming {
   background-color: var(--white);
}
.coming .container {
   display: flex;
   grid-gap: 40px 60px;
   flex-flow: nowrap;
   justify-content: space-between;
}
.coming .container>div {
   width: 50%;
}
.coming .container .offer_content {
   margin-top: 40px;
   width: 40%;
}
.sub_content h4 {
   font-size: var(--fs20);
   line-height: 26px;
   font-weight: 700;
   text-transform: capitalize;
   margin-top: 24px;
}
.sub_content p {
  font-size: var(--fs18);
  line-height: 26px;
  font-weight: 500;
  margin-top: 16px;
  color: rgba(0, 11, 51, 60%);
}
.blue_btn a, .img_contant a, .card_details a,button.withdraw_sub,.successfully_popup a, a.program_btn, a.booking_btn {
   padding: 11px 20px;
   background-color: var(--bg_blue);
   color: var(--white);
   margin-top: 40px;
   border-radius: 3px;
   position: relative;
   font-size: var(--fs16);
   line-height: 26px;
   min-width: 148px;
   display: flex;
   justify-content: center;
   max-width: fit-content;
   overflow: hidden;
   cursor: pointer;
   transition: all 0.3s;
}
.blue_btn a span, .img_contant a span, .card_details a span,.successfully_popup a span {
   position: relative;
   z-index: 2;
}
.blue_btn a::before, .img_contant a::before, .card_details a::before,.successfully_popup a::before {
   content: "";
   display: block;
   width: 0%;
   height: 100%;
   background-color: var(--blue);
   position: absolute;
   left: 0px;
   top: 0px;
   border-radius: 3px;
   transition: all 0.3s;
}
.blue_btn a:hover::before, .img_contant a:hover::before, .card_details a:hover::before,.successfully_popup a:hover::before  {
   width: 100%;
}
.coming::before {
   content: "";
   height: 550.73px;
   width: 380.72px;
   position: absolute;
   bottom: 0px;
   right: 0px;
   background-image: url(../../public/images/Vector.webp);
   background-repeat: no-repeat;
   background-size: contain;
   background-position: right;
}
/*======================|| Coming Offers Css End ||===========================*/
/*======================|| About Css Start ||===========================*/
.about_inner:nth-child(odd) {
   background-color: rgba(61, 160, 216, 2%);
}
.about_inner:nth-child(even) .sec_head {
   order: 2;
}
.about_inner:nth-child(even) .about_img {
   order: 1;
}
.about_inner .container, .traveler .container {
   display: flex;
   flex-flow: wrap;
   grid-gap: 40px 60px;
   align-items: center;
   justify-content: space-between;
   align-items: flex-start;
}
.about_inner .sec_head, .traveler .container .about_img {
   width: calc(100% - 794.39px - 60px);
   height: fit-content;
}
.about_inner .about_img {
   position: relative;
   height: 467.39px;
   width: 794.39px;
}
.sec_head h4 {
   font-size: var(--fs20);
   font-family: 'Tajawal';
   color: var(--sky_blue);
   margin-bottom: 16px;
   line-height: 28px;
   font-weight: 500;
}
.img_contant {
   position: absolute;
   top: calc(50% - 70px);
   transform: translateY(-50%);
   left: 15%;
}
.img_contant h3 {
   font-size: 28px;
}
.img_contant p {
   font-size: var(--fs18);
   margin: 14px 0px 30px;
}
.img_contant * {
   color: var(--white);
}
.img_contant a {
   background-color: var(--sky_blue);
   height: fit-content;
   padding: 16px 34.96px;
   font-size: var(--fs16);
   margin-top: 0px;
}
.img_contant a::before, .img_contant a {
   border-radius: 50px;
}
.about_img>a {
   position: absolute;
   left: calc(50% + 0px);
   top: calc(50% - 20px);
   transform: translate(-50%, -50%);
}
.about_inner .blue_btn a {
   margin-top: 30px;
}
/*=======================|| About Css End ||===========================*/
/*======================|| Modal Css start ||==========================*/
.modal {
   max-width: 1024px;
   margin: 0px auto;
   position: fixed;
   top: 50%;
   left: 50%;
   z-index: 100;
   transform: translate(-50%, -50%);
}
.modal video {
   width: 100%;
   height: 450px;
   object-fit: cover;
   border-radius: 8px;
}
.overlay {
   background-color: var(--blue);
   position: fixed;
   top: 0px;
   left: 0px;
   width: 100%;
   height: 100%;
   z-index: 99;
   opacity: 0.85;
}
/*======================|| Modal Css End ||===========================*/
/*======================|| Traveler Css ||===========================*/
.traveler .container .sec_head {
   width: calc(100% - 42% - 60px);
}
.traveler .container .about_img {
   width: calc(42% - 60px);
   display: flex;
   flex-flow: wrap;
   grid-gap: 60.32px;
}
.img_list {
   display: flex;
   grid-gap: 10px;
   padding-top: 20px;
}
.img_list img {
   display: block;
   width: calc(100%/3 - 7px);
}
.traveler .container {
   align-items: center;
}
.travel_box {
   display: flex;
   flex-flow: wrap;
   grid-gap: 29.3px;
   align-items: center;
   position: relative;
   width: 100%;
}
.travel_box span {
   width: 75.7px;
   height: 75.7px;
   align-items: center;
   border-radius: 50%;
   display: flex;
   justify-content: center;
   background-color: var(--white);
   color: var(--white);
   font-size: var(--fs18);
   position: relative;
   border: 2px solid var(--sky_blue);
}
.travel_box div {
   width: calc(100% - 75.5px - 29.5px);
}
.travel_box span::before {
   background-color: var(--sky_blue);
   width: 80%;
   position: absolute;
   height: 80%;
   border-radius: 50%;
   left: 50%;
   top: 50%;
   transform: translate(-50%, -50%);
   z-index: 0;
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: var(--fs18);
   font-family: 'Tajawal';
}
.travel_box h4 {
   font-size: var(--fs20);
   line-height: 26px;
   color: var(--blue);
   margin-bottom: 8px;
}
.travel_box p {
   font-size: var(--fs18);
   line-height: 26px;
   color: rgba(0, 11, 51, 70%);
}
.travel_box::before {
   content: "";
   background-color: #F2F2F2;
   display: block;
   width: 3px;
   height: 140px;
   position: absolute;
   top: 75.5px;
   left: 36px;
}
.travel_box:last-child::before {
   display: none;
}
.traveler .about_img {
   counter-reset: count;
}
.traveler .travel_box span::before {
   counter-increment: count;
   content: counter(count);
}
.traveler .about_inner:nth-child(even) {
   background-color: var(--light);
}
.traveler .about_inner:nth-child(odd) {
   background-color: var(--white);
}
/*============|| Traveler Css End ||===========================*/
/*============|| Featured Css Start ||===========================*/
.featured .sec_head, .screenshot .sec_head {
   text-align: center;
}
.featured {
   background-color: var(--light);
}
.card_box img {
   display: block;
   border-top-left-radius: 12px;
   border-top-right-radius: 12px;
   width: 100%;
}
.card_details {
   padding: 20px;
   background-color: var(--white);
}
.card_details h3 {
   font-size: var(--fs30);
   color: var(--blue);
   font-weight: 600;
}
.card_details {
   text-align: center;
}
.card_details p {
   font-size: var(--fs20);
   line-height: 26px;
   font-weight: 500;
   margin: 8px 0px;
}
.card_details h3 span, .card_details p span {
   color: var(--sky_blue);
}
.card_details a {
   height: 42px;
   width: 100%;
   padding: 8px;
   max-width: 100%;
   margin-top: 20px;
}
.card_inner {
   display: flex;
   flex-flow: wrap;
   grid-gap: 30px 46px;
   max-width: 90%;
   margin: 0px auto;
}
.card_inner>div {
   width: calc(100%/3 - 30.67px);
   box-shadow: 0px 4px 40px 0px #2B59FF14;
   border-radius: 10px;
}
.screen_owl img {
   width: 100%;
   display: block;
   max-width: 100%;
}
.screen_owl .item {
   max-height: 455px;
   overflow: hidden;
   position: relative;
}
.screen_owl .item img {
   width: 100%;
   object-fit: cover;
   transition: all 3s;
   object-position: top;
}
.screen_owl .item:hover img {
   transform: translateY(calc(-100% + 455px));
}
.screen_owl .item img:nth-child(2) {
   position: absolute;
   top: 0px;
   left: 0px;
   opacity: 0;
   transition: all 0.3s;
}
.screen_owl .owl-item.center .item img:nth-child(2) {
   opacity: 1;
}
.screen_owl .owl-item.center .item {
   border-radius: 40px;
}
.screen_owl .owl-dots span {
   background-color: red;
   width: 10px;
   height: 10px;
}
.screen_owl .owl-dots {
   display: flex;
   justify-content: center;
   grid-gap: 10px;
   align-items: center;
   margin-top: 20px;
}
.screen_owl .owl-dots button.owl-dot {
   background-color: var(--bg_blue);
   border-radius: 50%;
   width: 12px;
   height: 12px;
   opacity: 0.5;
   transition: all 0.3s;
}
.screen_owl .owl-dots button.owl-dot.active {
   opacity: 1;
}
.screen_owl .owl-dots {
   margin-top: var(--ptb60) !important;
}
.counter {
   background-color: var(--light);
}
.counter .container {
   display: flex;
   justify-content: space-between;
   grid-gap: 30px;
   align-items: center;
}
.counter .container>div {
   width: calc(100%/4 - 12px);
   display: flex;
   flex-flow: wrap;
   grid-gap: 24px 40px;
   align-items: center;
}
.counter_details h3 {
   font-size: var(--fs60);
   line-height: 72px;
   letter-spacing: -0.5px;
   color: var(--blue);
   display: flex;
   align-items: center;
}
.counter .container>div img {
   max-width: 100%;
   display: block;
   height: fit-content;
}
.counter_details p {
   font-size: var(--fs16);
   line-height: 24px;
   text-align: center;
   font-weight: 600;
}
.testimonial_right img {
   width: 100%;
   display: block;
   max-width: 100%;
   max-height: 517px;
   object-fit: contain;
}
/*==============|| Featured Css End ||===========================*/
/*============|| Testimonial Css End ||==========================*/
.testimonial .container {
   display: flex;
   grid-gap: 40px;
   justify-content: space-between;
}
.testimonial .container>div {
   width: calc(45% - 20px);
}
.testimonial_owl .item img {
   width: 30px !important;
   margin-bottom: 30px;
}
.testimonial_left .sec_head h2 {
   text-align: left;
   margin: 0px;
}
.testimonial_owl .item p {
   font-size: var(--fs18);
   line-height: 26px;
   color: rgba(0, 11, 51, 80%);
}
.testimonial_owl .item h4 {
   font-size: var(--fs16);
   line-height: 24px;
}
.testimonial_owl .item .author p {
   font-size: var(--fs18);
   line-height: 26px;
   margin: 0px;
   color: rgba(0, 11, 51, 70%);
}
.testimonial_owl .item .author {
   margin-top: 16px;
}
.testimonial_owl .owl-nav button span {
   display: none !important;
}
.testimonial_owl .owl-nav button {
   background-image: url('../../public/images/prev.webp') !important;
   width: 40px;
   height: 40px;
   background-repeat: no-repeat !important;
   background-position: center !important;
   background-size: contain !important;
   border: unset !important;
   margin: 0px !important;
}
.testimonial_owl .owl-nav button:hover {
   background-color: unset !important;
}
.testimonial_owl .owl-nav {
   display: flex;
   align-items: center;
   grid-gap: 20px;
   margin-top: 40px !important;
}
.testimonial_owl .owl-prev {
   transform: rotate(180deg);
}
.testimonial_owl .item p {
   max-width: 80%;
}
/*==================|| Testimonial Css End ||==========================*/
/*==================|| App Store Css Start ||==========================*/
.aap_sec {
   background-repeat: no-repeat;
   background-size: cover;
   background-position: center;
}
.aap_sec .sec_head {
   text-align: center;
}
.aap_sec .sec_head * {
   color: var(--white);
}
.aap_sec .app_store {
   justify-content: center;
}
.aap_sec {
  position: relative;
  z-index: 1;
}
.aap_sec::before {
  content: "";
 /* background-color: red;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  z-index: -1;
  opacity: 0.5;*/
}
/*=====================|| App Store Css End ||=======================*/
/*=====================|| Footer Css Start || =======================*/
.footer_logo a img {
   height: 48px;
   object-fit: contain;
}
.footer_grid {
   display: flex;
   flex-flow: wrap;
   justify-content: space-between;
   grid-gap: 30px;
}
.footer_logo p {
   margin: 20px 0px 30px;
}
.footer_grid>div {
   width: fit-content;
}
ul.social_links {
   padding: 0px;
   margin: 0px;
   list-style: none;
   display: flex;
   align-items: center;
   grid-gap: 40px;
}
ul.footer_links {
   padding: 0px;
   margin: 0px;
   list-style: none;
   display: flex;
   flex-direction: column;
   grid-gap: 15px;
}
ul.footer_links li a {
   display: block;
   font-size: var(--fs18);
   line-height: 26px;
   font-weight: 500;
   color: var(--blue);
}
.footer_menu h4 {
   margin-bottom: 30px;
}
footer.footer {
   padding: var(--ptb90) 0px 0px 0px;
   background-color: var(--white);
}
.copyright P {
   text-align: center;
}
.copyright {
   padding: 30px 0px;
   margin-top: var(--ptb60);
   border-top: 1px solid rgba(0, 11, 51, 10%);
}
.whts_icon {
   position: fixed;
/*      top: 50%; */
   right: 60px;
   bottom: 60px;
   background-color: #29A71A;
   transition: 0.2s linear infinite;
   width: 70px;
   height: 70px;
   border-radius: 50%;
   display: flex;
   align-items: center;
   justify-content: center;
   z-index: 98;
}
.whts_icon a, .whts_icon a img {
   position: relative;
   display: block;
   object-fit: contain;
}
.whts_icon:before, .whts_icon:after {
   content: "";
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   animation: whtsp 3s linear infinite;
   background-color: #29A71A;
   z-index: -1;
   border-radius: 50%;
   transition: 0.4s;
}
.whts_icon:after {
   animation: whtsp 5s linear infinite;
}
@keyframes whtsp {
   0% {
       transform: scale(1);
  }
   50% {
       transform: scale(1.2);
       opacity: 0.5 
  }
   100% {
       transform: scale(1.4);
       opacity: 0.2 
  }
}
/*=====================|| Footer Css End || =====================*/
/*==================|| Contact Page Css Start || ===================*/
.banners>div.container {
   display: flex;
   flex-direction: column;
   grid-gap: 40px;
   align-items: center;
}
.banner_head h1, .banner_head p {
   text-align: center;
}
.banner_head h1 {
   font-size: var(--fs70);
   line-height: 79px;
   font-weight: 700;
   color: var(--white);
}
.bredcrub a, .bredcrub p {
   align-items: center;
   color: var(--white);
   font-size: var(--fs16);
   line-height: 24px;
   font-weight: 700;
}
.banner_head {
   display: flex;
   flex-direction: column;
   grid-gap: 20px;
   align-items: center;
}
.banner_head p {
   font-size: var(--fs16);
   line-height: 24px;
   font-weight: 700;
   color: rgba(255, 255, 255, 80%);
}
.bredcrub {
   display: flex;
   grid-gap: 10px;
   align-items: center;
   color: var(--white);
}
.banners {
   padding: var(--ptb100)0px;
   position: relative;
   z-index: 1;
   background-repeat: no-repeat;
   background-size: cover;
   background-position: bottom center;
}
.banners::before {
   content: "";
   background: radial-gradient(109.63% 37.62% at 50% 50%, var(--sky_blue) 0%, #0077BB 100%);
   position: absolute;
   top: 0;
   bottom: 0;
   width: 100%;
   z-index: -1;
/*     opacity: 0.90;*/
   mix-blend-mode: multiply;
}
.banners::after {
   content: "";
   background: url(../../public/images/banner-radius-bg.webp);
   position: absolute;
   top: 0;
   bottom: 0;
   width: 100%;
   background-position: bottom center;
   background-size: contain;
   background-repeat: no-repeat;
   height: calc(100% - 40px);
   z-index: -1;
}
/*===============|| Contact Details Css Start || ===================*/
.contact_details {
   padding: var(--ptb120) 0px;
   background-color: #FBFCFF;
}
.contact_grid {
   display: flex;
   grid-gap: 24px;
   flex-flow: wrap;
}
.contact_grid>div.contact_box {
   width: calc(100%/3 - 16px);
}
.contact_box {
   background-color: var(--white);
   box-shadow: 0px 4px 40px 0px #2B59FF14;
   padding: 50px;
   border-radius: 10px;
}
.icon {
   width: 60px;
   height: 60px;
   border-radius: 50%;
   margin: 0 auto 20px;
   background: radial-gradient(109.63% 37.62% at 50% 50%, var(--sky_blue) 0%, #0077BB 100%);
   display: flex;
   align-items: center;
   justify-content: center;
}
.contact_box a {
   text-align: center;
   display: block;
   font-size: var(--fs18);
   line-height: 26px;
   font-weight: 500;
   color: var(--blue);
}
/*==================|| Map Form Css Start || =====================*/
.map_box iframe {
   border-radius: 10px;
   display: block;
   max-width: 100%;
   width: 100%;
}
.form_map {
   display: flex;
   flex-flow: wrap;
   grid-gap: 24px;
   padding-top: var(--ptb100);
}
.contact_form {
   padding: 40px 34px;
   background-color: var(--whte);
   box-shadow: 0px 4px 40px 0px rgba(43, 89, 255, 0.08);
   border-radius: 10px;
}
.form_group input, .form_group textarea, .submit_btn input, .form_edit input,.booking_group input,.booking_group select, .booking_group textarea {
  display: block;
  width: 100%;
  padding: 8px 36px;
  border: 1px solid rgba(189, 189, 189, 0.25);
  background-color: var(--white);
  outline: unset;
  resize: unset;
  border-radius: 5px;
  font-size: var(--fs14);
  line-height: 20px;
  font-weight: 400;
  height: 48px;
}
.booking_group select {
  cursor: pointer;
}
.form_group textarea,.booking_group textarea {
   height: 150px;
}
.form_map>div {
   width: calc(100%/2 - 12px);
}
.form_grid {
   display: flex;
   flex-direction: column;
   grid-gap: 20px;
}
.submit_btn {
   margin-top: 20px;
}
.submit_btn input {
  background-color: rgba(34, 45, 97, 1);
  border-radius: 10px;
  color: var(--white);
  border: unset;
  padding: 10px 33px;
  font-size: var(--fs16);
  line-height: 24px;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
}
.submit_btn input:hover {
/*    background: linear-gradient(235.69deg, #3DA0D8 32.76%, #3DA0D8 106%);*/
}
/*=================|| Contact Page Css Start || ====================*/
/*==================|| About Page Css Start ||======================*/
.team_inner {
   display: flex;
   flex-flow: wrap;
   grid-gap: 24px;
}
.team_inner>div.card_box {
   width: calc(100%/4 - 18px);
   padding: 20px 20px 24px 20px;
   box-shadow: 0px 4px 40px 0px transparent;
   transition: all 0.3s;
   border-radius: 5px;
   background-color: var(--white);
}
.team_inner>div.card_box:hover {
   box-shadow: 0px 4px 40px 0px #2B59FF14;
}
.team_inner>div.card_box img {
   border-radius: 5px;
}
.team_inner>div.card_box .card_details {
   padding: 30px 16px 0px 16px;
}
.team_inner>div.card_box .card_details h3, .team_inner>div.card_box .card_details p, .portfolio-info h3, .portfolio-info p {
   text-align: left;
   margin-bottom: 5px;
}
.team_inner>div.card_box .card_details p, .portfolio-info p {
   margin: 0px;
   font-size: var(--fs18);
   line-height: 26px;
   font-weight: 500;
}
.team_inner>div.card_box .card_details h3, .portfolio-info h3 {
   font-size: var(--fs20);
   font-weight: 500;
   line-height: 26px;
}
.portfolio-info h3 {
   color: var(--white);
}
.portfolio-info .card_details {
   background-color: transparent;
   padding: 0px 0px 0px;
   transform: translateY(20px);
   opacity: 0;
   transition: all 0.3s;
}
.portfolio-container .box:hover .portfolio-info .card_details {
   opacity: 1;
   transform: translateY(0px);
}
.our_team.gallery_team .card_box {
   padding: 0px;
   position: relative;
}
.our_team.gallery_team .card_box img {
   padding: 0px;
   border-radius: 10px;
}
.our_team.gallery_team .card_box .card_details {
   position: absolute;
   padding: 0px;
   left: 20px;
   background-color: unset;
   opacity: 0;
   bottom: 0px;
   transition: all 0.3s;
}
.our_team.gallery_team .card_box:hover .card_details {
   bottom: 20px;
   opacity: 1;
}
.our_team.gallery_team .card_box:hover::before {
   background-color: rgba(61, 160, 216, 90%);
}
.our_team.gallery_team .card_box::before, .portfolio-item .box::before {
   content: "";
   background-color: transparent;
   position: absolute;
   top: 0;
   bottom: 0;
   right: 0;
   left: 0px;
   border-radius: 10px;
   z-index: 0;
   transition: all 0.3s;
}
.gallery_team .team_inner>div.card_box .card_details h3, .gallery_team .team_inner>div.card_box .card_details p {
   color: var(--white);
}
.plus_icon {
   position: absolute;
   top: 0px;
   right: 20px;
   opacity: 0;
   transition: all .3s;
}
.team_inner>div.card_box:hover .plus_icon {
   top: 20px;
   opacity: 1;
}
.about_testimonial {
   background-color: var(--white);
}
.our_team, .gallery_team, .faq_Navigation, .portfoio {
   background: rgba(61, 160, 216, 2%);
}
.topics_box h3 {
   color: var(--white);
   font-size: var(--fs30);
   font-weight: 700;
   line-height: 42px;
   background: radial-gradient(109.63% 37.62% at 50% 50%, var(--sky_blue) 0%, #0077BB 100%);
   width: fit-content;
   padding: 26px 50px 26px 30px;
}
ul.faq_topics {
   padding: 40px 30px;
   margin: 0px;
   list-style: none;
   background-color: var(--white);
}
ul.faq_topics li a {
   display: block;
   padding: 15px 0px 15px 0px;
   font-size: var(--fs20);
   color: var(--blue);
   font-family: 'Tajawal';
   line-height: 26px;
   font-weight: 400;
}
ul.faq_topics li a.active {
   color: var(--sky_blue);
}
ul.faq_topics li:last-child a {
   padding: 15px 0px 0px 0px;
}
ul.faq_topics li:first-child a {
   padding: 0px 0px 15px 0px;
}
.faq_Navigation>div.container {
   display: flex;
   flex-flow: wrap;
   grid-gap: 120px;
}
.topics_box {
   width: 304px;
}
.faq_Navigation>div.container>div.topic_details {
   width: calc(100% - 304px - 120px);
}
/*=======================|| 20.06.2024 ||==========================*/
.tourism_tours>div.tourism_inner {
   max-width: 80%;
}
.tourism_tours>div>div.tourism_inner {
   max-width: 80%;
   margin: 0 auto;
}
.tourism_inner>div.card_box {
   width: calc(100%/3 - 16px);
}
.our_team.gallery_team.tourism_tours .card_box::before {
   background: linear-gradient(0.49deg, #000000 0.47%, rgba(0, 0, 0, 0) 44.93%);
   background-color: unset;
}
.our_team.gallery_team.tourism_tours .card_box:hover::before {
   background-color: unset;
}
.team_inner.tourism_inner>div.card_box:hover {
   box-shadow: unset;
}
.tourism_tours.our_team.gallery_team .card_box:hover .card_details {
   bottom: 20;
}
.tourism_tours.our_team.gallery_team .card_box .card_details {
  left: 0px;
  background-color: unset;
  opacity: 1;
  bottom: 20px;
  width: 100%;
  height: 100%;
  bottom: 20px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: end;
}
.tourism_tours.our_team.gallery_team .card_box .card_details h3 {
  text-align: center;
  margin: 0px;
  font-size: var(--fs30);
  line-height: 36px;
  margin-bottom: 20px;
}
/*------------------|| My Account Css ||-------------------------*/
.account_left {
   position: relative;
}
.white_logo {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}
.account_left {
   position: relative;
}
.white_logo {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}
.account_left img {
   width: 100%;
   max-height: 209px;
}
.account_content {
   width: 620px;
   padding: 100px 70px;
}
.account_inner {
   display: flex;
   align-items: center;
}
.account_inner .account_left {
   width: calc(100% - 620px);
   background-repeat: no-repeat;
   background-size: cover;
   background-position: top right;
   min-height: 986px;
}
.account_create .account_left {
   min-height: 1370px;
}
.account_left::before {
   content: "";
   width: 100%;
   height: 100%;
   background: radial-gradient(109.63% 37.62% at 50% 50%, rgba(61, 160, 216, 0.9) 0%, rgba(0, 119, 187, 0.9) 100%);
   position: absolute;
}
.account_content>img {
   max-height: 108px;
   margin: 0 auto;
}
h2.box_heading {
  font-size: var(--fs48);
  text-align: center;
  line-height: 58px;
  margin: 40px 0px 16px 0px;
  letter-spacing: -0.5px;
}
.login_account .form_group {
   margin-bottom: 24px;
}
.login_account .form_group label {
   display: block;
   margin-bottom: 16px;
   font-weight: 500;
   font-size: var(--fs20);
   line-height: 26px;
   color: rgba(0, 11, 51, 80%);
}
.password_box {
   position: relative;
}
.phone_box {
   display: flex;
   grid-gap: 8px;
}
.remember_box {
   display: flex;
   grid-gap: 12px;
   width: calc(100% - 124px);
}
.login_account .form_group .remember_box input {
  max-width: 16px;
  height: 16px !important;
  margin: 5px 0px 0px 0px;
}
.form_group.remember_me {
   display: flex;
}
.login_account .remember_box label {
   margin: 0px;
   color: rgba(0, 11, 51, 60%);
}
.login_account .remember_box label {
  margin: 0px;
}
.create_account P {
   text-align: center;
   display: flex;
   grid-gap: 4px;
   align-items: center;
   justify-content: center;
   font-size: var(--fs16);
   line-height: 24px;
   font-weight: 700;
   color: rgba(0, 11, 51, 80%);
}
.login_account .form_group select,.withdraw_group select,.booking_group select {
   appearance: none;
   background-image: url(../../public/images/down-arrow.svg);
   background-repeat: no-repeat;
   background-size: auto;
   background-position: calc(100% - 10px);
}
.login_account .form_group input, .login_account .form_group select {
  height: 48px;
  font-size: var(--fs18);
  line-height: 26px;
  font-weight: 500;
  color: rgba(0, 11, 51, 80%);
}
.login_account .form_group select {
  color: rgba(0, 11, 51, 50%);
}
.login_account .form_group .phone_box input {
  width: calc(100% - 112px);
  padding: 8px 24px;
  border: 1px solid rgba(189, 189, 189, 0.25);
  border-radius: 10px;
}

.login_account .form_group select {
   width: 104px;
   display: block;
   border-radius: 10px;
   border: 1px solid rgba(189, 189, 189, 0.25);
   outline: none;
   padding: 10px;
}
.login_account .form_group .password_input {
  width: 100%;
  padding: 8px 60px 8px 34px;
}
.login_account .form_group .show_password {
   position: absolute;
   right: 30px;
   top: 50%;
   cursor: pointer;
   transform: translateY(-50%);
}
.login_account .form_group .remember_me {
   display: flex;
   align-items: center;
}
.login_account .form_group .forgot_password {
   width: 124px;
   font-size: var(--fs16);
   line-height: 24px;
   font-weight: 700;
   color: rgba(0, 11, 51, 70%);
}
.login_button {
  width: 100%;
  padding: 10px 24px;
  background-color: var(--bg_blue);
  border-radius: 3px;
  cursor: pointer;
  font-size: var(--fs16);
  margin-bottom: 40px;
  display: block;
  margin-top: 30px;
  color: var(--white);
  position: relative;
  outline: unset;
  border: unset;
  line-height: 24px;
  font-weight: 700;
  height: 48px;
}
.login_button:hover {
/*     z-index: 0;*/
}
.create_account a {
   text-decoration: none;
   color: var(--sky_blue);
   font-size: 17px;
}
button.login_button::before,button.withdraw_sub::before {
   /*content: "";
   display: block;
   width: 0px;
   height: 100%;
   position: absolute;
   left: 0px;
   top: 0;
   transition: all 0.3s;*/
}
button.login_button:hover::before,button.withdraw_sub:hover::before {
  /* background-color: var(--blue);
   width: 100%;
   z-index: -1;*/
}
button.withdraw_sub:hover {
/*    z-index: 0;*/
}
.gender_options {
   display: flex;
   grid-gap: 24px;
   flex-flow: wrap;
   justify-content: center;
}
.login_account .form_group .gender_options label {
  display: flex;
  position: relative;
  margin: 0px;
}
.account_create h2.box_heading {
   margin: 0px 0px 24px 0px;
}
.avatar_img,.edit_img {
   width: 130px;
   height: 130px;
   border-radius: 50%;
}
.upload_btn,.edit_btn {
   position: absolute;
   bottom: 0;
   right: 0;
   background-color: var(--white);
   border: none;
   border-radius: 50%;
   padding: 5px;
   cursor: pointer;
}
.upload_btn img,.edit_btn img {
   width: 20px;
   height: 20px;
}
.avatar_inner,.edit_inner {
  position: relative;
  width: 130px;
  height: 130px;
  margin: 0 auto 20px;
}
.avatar_inner input[type="file"],.edit_inner input[type="file"] {
  display: none;
}
.login_account .form_group .show_password img.show {
  display: none;
}
.login_account .form_group span.show_password.active img.hide {
  display: none;
}
.login_account .form_group span.show_password.active img.show {
  display: block;
}

span.gender_cat {
   display: flex;
   padding: 5px 16px 5px 20px;
   border: 1px solid transparent;
   background-color: rgba(250, 251, 252, 1);
   border-radius: 100px;
   grid-gap: 12px;
   align-items: center;
}
.login_account .form_group .gender_options label input {
   display: none;
}
.login_account .form_group .gender_options label input:checked~span {
   border: 1px solid var(--sky_blue);
}
.forget_act .account_content>h2,.new_pswd .account_content>h2,.verify_code .account_content>h2 {
   text-align: left;
}
.forget_act .account_content>p,.new_pswd .account_content>p,.verify_code .account_content>p {
   margin-bottom: 30px;
   font-size: var(--fs18);
   line-height: 26px;
   font-weight: 500;
}
.verify_code .account_content>p {
   margin: 0px;
}
.passwrd_btn {
   margin: 40px 0px 0px 0px;
}
.verify_code .account_content>p a {
   display: block;
   font-weight: 700;
   color: var(--blue);
}
.otp_inputs {
   display: flex;
   margin: 40px 0px;
   grid-gap: 9px;
}
.otp_input {
   width: 84px;
   height: 48px;
   margin: 0px;
   font-size: var(--fs18);
   text-align: center;
  border: 1px solid rgba(189, 189, 189, 0.25);
   border-radius: 10px;
   outline: unset;
}
.varify_btn {
   margin: 0px 0px 40px 0px;
}
.accordion {
   max-width: 856px;
   margin-left: auto;
   display: flex;
   flex-flow: wrap;
}
.accordion-item {
   background-color: transparent;
   box-shadow: 0px -3px 13px 2px transparent;
   width: 100%;
   border-bottom: 1px solid #E6EFFE;
   transition: background 0.3s, box-shadow 0.3s, margin 0.3s;
}
.accordion-item.active {
   background-color: var(--white);
   box-shadow: 0px 4px 40px 0px #2B59FF14;
   border-radius: 10px;
   border: unset;
}
.accordion-header {
  cursor: pointer;
  padding: 30px 30px;
  font-size: var(--fs20);
  line-height: 26px;
  font-weight: 500;
  font-family: 'Tajawal';
  margin: 0px;
  color: var(--blue);
  display: flex;
  grid-gap: 18px;
  justify-content: space-between;
  align-items: center;
}
.accordion-item .accordion-header span {
  width: 16px;
  height: 16px;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.accordion-item .accordion-header span.open_plus {
  display: flex;
}
.accordion-item .accordion-header span.open_minus {
  display: none;
}
.accordion-item.active .accordion-header span.open_plus {
  display: none;
}
.accordion-item.active .accordion-header span.open_minus  {
  display: flex;
}  
.accordion-content {
   display: none;
   padding: 20px 30px 30px;
}
.accordion-item.active .accordion-header {
   padding-bottom: 0px;
}
.accordion-item~.accordion-item.active {
   border-top: 1px solid #E6EFFE;
   margin-top: 30px;
}
.accordion-item.active+.accordion-item {
   border-top: 1px solid #E6EFFE;
   margin-top: 30px;
}
.accordion-item p {
   font-size: var(--fs18);
   line-height: 26px;
   font-weight: 500;
   color: rgba(0, 11, 51, 80%);
}
.accordion {
   display: none;
}
.accordion:first-child {
   display: block;
}
.portfolio-info::before {
   content: "";
   background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gPHBhdGggZD0iTTE2IDMxQzI0LjI4NDMgMzEgMzEgMjQuMjg0MyAzMSAxNkMzMSA3LjcxNTczIDI0LjI4NDMgMSAxNiAxQzcuNzE1NzMgMSAxIDcuNzE1NzMgMSAxNkMxIDI0LjI4NDMgNy43MTU3MyAzMSAxNiAzMVoiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj48L3BhdGg+IDxwYXRoIGQ9Ik0xNiAxMFYyMiIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPjwvcGF0aD4gPHBhdGggZD0iTTEwIDE2SDIyIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+PC9wYXRoPg0KICAgICAgICAgICAgICAgICAgICAgPC9zdmc+);
   width: 32px;
   height: 32px;
   background-size: contain;
   background-repeat: no-repeat;
   background-position: center;
   position: absolute;
   top: 0px;
   right: 20px;
   transition: all 0.3s;
   opacity: 0;
}
.portfolio-container .box:hover .inner .portfolio-info::before {
   top: 20px;
   opacity: 1;
}
/*------------------|| News Css ||-------------------------*/
.news .container {
  display: flex;
  grid-gap: 24px;
  justify-content: space-between;
  flex-flow: wrap;
}
.news_list {
  padding: 40px;
  width: 416px;
  display: flex;
  flex-flow: wrap;
  grid-gap: 30px;
  height: fit-content;
}
.list-item,.news_list{
  background-color: var(--white);
  border-radius: 10px;
/*    box-shadow: 0px -3px 13px 2px rgb(201 202 242 / 20%);*/
  box-shadow: 0px 4px 40px 0px #2B59FF14;
}
.list-item{
   border-radius: 10px;
}
.search_form input {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px -3px 13px 2px rgb(201 202 242 / 20%);
  display: block;
  width: 100%;
  border: unset;
  min-height: 60px;
  padding: 20px 30px 20px 56px;
  color: var(--blue);
  font-size: var(--fs18);
  font-family: 'Tajawal';
  outline: unset;
  font-weight: 500;
}
.news_group{
  position: relative;
}
.news_group button {
  position: absolute;
  top: 25px;
  background-color: transparent;
  border: unset;
  outline: unset;
  cursor: pointer;
  left: 30px;
  padding: 0px;
}
.news_list > * {
  width: 100%;
}
.news_list h3 {
  font-size: var(--fs20);
  color: var(--blue);
  line-height: 26px;
  font-weight: 500;
  margin-bottom: 15px;
}
.categories ul{
  list-style: none;
  display: flex;
  flex-flow: wrap;
  padding: 0px;
  margin: 0px;
}
.categories ul li{
  width: 100%;
}
.categories ul li a {
  text-decoration: none;
  color: rgba(0, 11, 51, 80%);
  font-size: var(--fs18);
  line-height: 26px;
  font-weight: 500;
  padding: 15px 0px;
  display: flex;
  align-content: center;
  grid-gap: 4px;
  border-top: 1px solid #EBF2FF;
}
.recent_news {
  display: flex;
  flex-flow: wrap;
  grid-gap: 20px;
  align-items: center;
  padding: 8px 0px;
/*    border-top: 1px solid rgba(55, 124, 253, 10%);*/
}
.recent_img {
  width: 130px;
}
.recent_img img {
  width: 130px;
  height: 120px;
  border-radius: 10px;
  object-fit: cover;
}
.recent .recent_detail {
  width: calc(100% - 130px - 20px);
}
.recent_detail p, .recent_detail h4 {
  color: var(--blue);
  font-size: var(--fs14);
  color: rgba(0, 11, 51, 80%);
  font-weight: 400;
}
.recent_detail h4 {
  font-weight: 900;
  margin-bottom: 10px;
}
.tags h3 {
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(55, 124, 253, 10%);
}
ul.tag_list {
  padding: 0px;
  list-style: none;
  margin: 0px;
  display: flex;
  flex-flow: wrap;
  grid-gap: 15px;
}
ul.tag_list li a {
  border: 1px solid #010C16;
  font-size: var(--fs16);
  color: rgba(1, 12, 22, 80%);
  line-height: 24px;
  padding: 7px 16px;
  border-radius: 4px;
  display: block;
  font-weight: 700;
}
ul.tag_list li a.active{
  background-color:#222D61;
  border-color:#222D61;
  color: var(--white);
}
.news_details {
  width: calc(100% - 416px - 24px);
}
.list-wrapper {
  display: flex;
  flex-flow: wrap;
  grid-gap: 24px;
  justify-content: space-around;
}
.list-item{
    width: 100%;
}
.list-wrapper .list-item {
  width: calc(100%/2 - 12px);
}
.news_img img{
  height: 283px;
  object-fit: cover;
  display: block;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  width: 100%;
}
ul.autor_detail {
  margin: 0px;
  list-style: none;
  display: flex;
  grid-gap: 24px;
  align-items: center;
  padding: 30px 20px 20px;
}
ul.autor_detail li{
  display:flex;
  grid-gap:10px;
  color:var(--sky_blue);
  font-size:14px;
  line-height:21px;
  font-weight: 500;
}
ul.autor_detail li img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}
.list-item .recent_content {
  padding: 0px 20px 30px;
  width: 100%;
}
.recent_content h3 {
  font-size: var(--fs20);
  line-height: 26px;
  color: var(--blue);
  font-weight: 500;
  margin-bottom: 16px;
}
.recent_content p {
  font-size: var(--fs18);
  line-height: 26px;
  color: rgba(0, 11, 51, 80%);
}
.recent_content span {
  margin-top: 16px;
  display: flex;
  color: var(--blue);
  font-size: var(--fs16);
  line-height: 24px;
  font-weight: 600;
}
.pagination  ul {
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-flow: wrap;
  grid-gap: 20px;
  list-style: none;
  padding-left: var(--ptb60);
}
.pagination ul li a,.pagination ul li span{
  width: 40px;
  height: 40px;
  font-size: var(--fs16);
  color: var(--blue);
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--blue);
  border-radius: 50%;
  font-family: 'Tajawal';
  font-weight: 600;
  transition: all 0.3s;
  box-shadow: 0px 4px 40px 0px #2B59FF14;
}
.pagination ul li .current,.pagination ul li a:hover{
  background-color: var(--bg_blue);
  border-color: var(--bg_blue);
  color: var(--white);
  box-shadow: unset;
}
.pagination ul .prev::before,.pagination ul .next::before{
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(../../public/images/news/prev.svg);
}
.pagination ul li .next,.pagination ul li .prev{
  background-color: var(--white);
}
.pagination ul .next:hover,.pagination ul .prev:hover{
  background-color: var(--bg_blue);
  border-color: var(--bg_blue);
}
.pagination ul .next::before{
  transform: rotate(-180deg);
}
.pagination ul li a:hover::before,.pagination ul li span:hover::before{
  filter: brightness(0) invert(1);
}
.pagination ul .prev,.pagination ul .next {
  font-size: 0px;
}
.news {
  /* background-image: linear-gradient(to bottom, var(--white), #F6F8FF ); */
  background-color: var(--white);
}
.recent_content span::after {
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  background: url(../../public/images/news/arrow.svg) no-repeat center;
  background-size: contain;
}
.recent_content span{
  display: flex;
  align-items: center;
  grid-gap: 12px;
}
.single_news .list-wrapper .list-item {
  width: 80%;
}
.single_news .list-wrapper .news_img img {
  height: 487px;
}
.single_news .pagination ul li:first-child ~ li{
  display: none;
}
.single_news .pagination ul li:first-child ~ li:last-child{
  display: flex;
}
.single_news  .pagination ul {
  padding: 0px;
  justify-content: space-between;
  max-width: 80%;
  margin: 0px auto;
}
.single_news .pagination ul li:first-child span,.single_news .pagination ul li:first-child a,
.single_news .pagination ul li:last-child span,.single_news .pagination ul li:last-child a{
  background-color: transparent !important;
  border-radius: unset;
  border: unset;
  width: fit-content;
}
.single_news .pagination ul li:first-child span::before,.single_news .pagination ul li:first-child a::before,
.single_news .pagination ul li:last-child span::before,.single_news .pagination ul li:last-child a::before {
  content: "Previous:";
  font-size: var(--fs16);
  color: #377CFD;
  background: transparent;
  width: 100%;
  height: fit-content;
  transform: unset;
  filter: unset;
}
.single_news .pagination ul li:last-child span::before,.single_news .pagination ul li:last-child a::before {
  content: "Next:";
}
.single_news .pagination ul li a {
  box-shadow: unset;
}
.pagination {
  width: 100%;
}
.single_news ul.autor_detail {
  margin-bottom: 10px;
}
.single_news .recent_content ul {
  padding-left: 20px;
}
.single_news  ul.social {
  display: flex;
  list-style: none;
  align-items: center;
  padding: 0px;
  grid-gap: 15px;
  align-items: center;
}
.client_comments,.review_box {
  padding: 20px 20px 15px 20px;
  box-shadow: 0px 4px 40px 0px #2B59FF14;
  max-width: 80%;
  margin: 0 auto;
  border-radius: 10px;
}
.client_comments > h4,.review_box > h4 {
  padding-left: 20px;
  font-size: var(--fs20);
  line-height: 26px;
  font-weight: 500;
  color: #377CFD;
}
.comment_diveder {
  border: 0.1px solid rgba(55, 124, 253, 10%);
  margin: 40px 0px;
}
.Comments_list > div.Comments_details,.reviewer_content {
  padding: 30px 20px 30px 30px;
  /* margin-bottom: 40px; */
  display: flex;
  flex-flow: wrap;
  grid-gap: 30px;
}
.Comments_details img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.comment_contant {
  width: calc(100% - 80px - 30px);
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
}
.comment_contant > p {
  color: rgba(0, 11, 51, 80%);
  font-size: var(--fs18);
  font-weight: 500;
  line-height: 26px;
}
.client_name h5 {
  color: var(--blue);
  font-size: var(--fs14);
  font-weight: 900;
  line-height: 22px;
}
.client_name p {
  color: rgba(0, 11, 51, 70%);
  font-size: var(--fs14);
}
.review_box {
  padding: 40px 40px 40px 40px;
  box-shadow: 0px 4px 40px 0px #2B59FF14;
  max-width: 80%;
  margin: 60px auto 0px auto;
}
.review_box > h4 {
  padding: 0px 0px 14px 0px;
}
.review_box > p {
  color: rgba(0, 11, 51, 80%);
  font-size: var(--fs14);
  line-height: 21px;
  font-weight: 400;
}
form#comment_form textarea, form#comment_form button {
  display: block;
  width: 100%;
  border: unset;
  outline: none;
  padding: 0px;
  resize: unset;
  font-size: var(--fs14);
  line-height: 20px;
  font-weight: 400;
  color: var(--blue);
  padding: 11px 30px;
  border-radius: 5px;
}
form#comment_form textarea {
  margin: 20px 0px;
  background: rgba(55, 124, 253, 5%);
  padding: 30px;
  height: 200px;
  overflow-y: auto;
}
form#comment_form button {
  background-color: var(--bg_blue);
  color: var(--white);
  font-size: var(--fs16);
  line-height: 26px;
  font-weight: 700;
  border-radius: 3px;
  cursor: pointer;
}
form#comment_form textarea::-webkit-scrollbar {
width: 2px;
}
form#comment_form textarea::-webkit-scrollbar-track {
background:var(--blue); 
border-radius: 8px;
}
form#comment_form textarea::-webkit-scrollbar-thumb {
background:var(--sky_blue); 
border-radius: 8px;
}
.review_box form.comment_form textarea::placeholder {
  color: rgba(0, 11, 51, 80);
}
/*------------------|| term_conditions Css Start ||-------------------------*/
.term_condition {
  background-color: rgba(61, 160, 216, 2%);
  padding: 120px 0px;
}
.term_condition > div.container > div {
  max-width: 856px;
  margin: 0 auto;
  padding: 60px 110px 40px 110px;
  border-radius: 10px;
  box-shadow: 0px 4px 40px 0px rgba(43, 89, 255, 0.08);
  background-color: var(--white);
}
.term_heading h2 {
  font-size: var(--fs30);
  line-height: 42px;
  font-weight: 700;
  margin-bottom: 20px;
}
.term_heading p {
  color: rgba(0, 11, 51, 70%);
}
.condition_contents > div:not(:last-child) {
  margin-bottom: 30px;
}
.condition_contents > div > p:not(:last-child) {
  margin-bottom: 10px;
}
/*------------------|| Edit Profile Css Start ||-------------------------*/
.profile_edit,.my_preferences  {
  background: rgba(61, 160, 216, 2%);
}
.profile_box {
  max-width: 80%;
  margin:  0 auto;
}
.edit_avatar {
   margin-bottom: 50px;
}
.form_edit label, .selection_box > label,.booking_group label {
  display: block;
  margin-bottom: 16px;
  font-weight: 500;
  font-size: var(--fs20);
  line-height: 26px;
  color: rgba(0, 11, 51, 80%);
}
.selection_box > label {
  display: block;
  margin-bottom: 16px;
}
.edit_grid {
  display: flex;
  flex-flow: wrap;
  grid-gap: 100px;
  margin-bottom: 40px;
}
.edit_box {
  margin-bottom: 40px;
}
.edit_grid > div {
  width: calc(100%/2 - 50px);
}
.edit_profile .form_edit select {
  width: 104px;
  min-width: 104px;
  display: block;
  border-radius: 10px;
  border: 1px solid rgba(189, 189, 189, 0.25);
  outline: none;
  padding: 10px;
  appearance: none;
  background-image: url(../../public/images/down-arrow.svg);
  background-repeat: no-repeat;
  background-size: auto;
  background-position: calc(100% - 10px);
}
.form_edit .gender_options {
  display: flex;
  grid-gap: 24px;
  flex-flow: wrap;
  justify-content: flex-start;
}
.edit_sub,.prebooking_sub {
  width: 100%;
  padding: 12px 24px;
  background-color: var(--bg_blue);
  border-radius: 3px;
  cursor: pointer;
  font-size: var(--fs16);
  display: block;
  margin-top: 48px;
  color: var(--white);
  position: relative;
  outline: unset;
  border: unset;
  line-height: 24px;
  font-weight: 700;
  transition: all 0.3s ;
}
.edit_sub:hover, .edit_sub:focus, .prebooking_sub:hover, .prebooking_sub:focus {
/*    background: linear-gradient(235.69deg, #3DA0D8 32.76%, #3DA0D8 106%);*/
}
.form_edit.gender label {
  margin-bottom: 30px;
}
.gender_selection {
  display: flex;
  margin: 0px;
  grid-gap: 24px;
}
.gender_option {
  display: flex;
  align-items: center;
  grid-gap: 12px;
  cursor: pointer;
  padding: 10px 24px;
  border: 1px solid transparent;
  border-radius: 100px;
  transition: border-color 0.3s, background-color 0.3s;
  background-color: #F1F1F1;
  height: 51px;
}
.gender_option img {
  width: 40px;
  height: 40px;
  margin: 0px;
}
.form_edit input, .form_edit select,.booking_group input,.booking_group select, .booking_group textarea {
  padding: 8px 36px;
  border: 1px solid rgba(189, 189, 189, 0.25);
  border-radius: 10px;
  font-size: var(--fs18);
  line-height: 26px;
  font-weight: 500;
  height: 48px;
  color: var(--blue);
}
.booking_group input[type="date"],.booking_group input[type="time"] {
  cursor: pointer;
}
.form_edit .phone_box input {
  width: calc(100% - 104px);
}
.gender_option span {
  font-size: var(--fs18);
  color: var(--black1);
  line-height: 26px;
  font-weight: 400;
}
.gender_option input[type="radio"] {
  display: none;
}
.genders_grid input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  margin: 0px;
}
.genders_grid {
  position: relative;
}
.genders_grid input:checked + label {
  border: 1px solid #3DA0D8;
  background-color: #FAFBFC;
}

/*=====================|| My Wallet Css Start ||=========================== */
.my_wallet {
  padding: 30px  0px 40px 0px;
  background: rgba(61, 160, 216, 2%);
}
.wallet_bal {
  background-color: var(--white);
  border-radius: 10px;
}
.wallet_bal img {
  max-width: 146px;
  margin: 0 auto;
}
.wallet_bal h4,.withdraw_bal h4  {
  font-size: 22px;
  line-height: 26px;
  font-weight: 700;
  margin: 24px 0px 12px 0px;
}
.wallet_bal h2 {
  color: var(--sky_blue);
  font-size: var(--fs40);
  line-height: 48px;
  font-weight: 900;
}
.history_box h4 {
  margin: 30px 0px 30px 0px;
}
.wallet_bal h4,.wallet_bal h2,.withdraw_bal p {
  text-align: center;
}
.withdraw_bal p {
  color: var(--light_blue);
}
.balance_grid {
  display: flex;
  flex-flow: wrap;
  grid-gap: 30px 48px;
}
.wallet_history {
  background-color: var(--white);
  border-radius: 10px;
  padding: 8px 0px 20px 0px;
}
.wallet_history > div.add_blnc {
  border-bottom: 1px dashed#0000001A;
  padding: 0px 30px 20px 30px;
}
.add_date, .withdraw_date {
  padding: 20px 30px 0px 30px;
}
.history_box h4 {
  margin: 30px 0px 30px 0px;
  font-size: var(--fs20);
  line-height: 28px;
  font-weight: 900;
}
.add_blnc h5 {
  margin-bottom: 12px;
  font-size: var(--fs18);
  line-height: 28px;
  font-weight: 500;
}
.add_date p,.withdraw_date p {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.add_date p span, .add_date p strong, .withdraw_date p span, .withdraw_date p strong {
  color: #ABABAB;
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
  display: block;
}
.add_date p strong,.withdraw_date p strong {
  font-size: 22px;
  line-height: 26px;
  font-weight: 700;
  color: #2DAE72;
}
.withdraw_date p strong {
  color: #EB5757;
}
.add_box,.withdraw_box {
  display: flex;
  flex-direction: column;
  grid-gap: 30px 30px;
}
.balance_grid > div {
  width: calc(100%/2 - 24px);
}
a.Withdraw_btn, a.add_btn {
  background: linear-gradient(235.69deg, #3DA0D8 32.76%, #3DA0D8 106%);
  padding: 10px 24px;
  height: 48px;
  display: block;
  text-align: center;
  font-size: var(--fs16);
  line-height: 26px;
  font-weight: 700;
  color: var(--white);
  margin-top: 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
a.Withdraw_btn {
  background: var(--bg_blue);
}
.withdraw_bal h4 {
  margin: 40px 0px 12px 0px;
}
.withdraw_group label {
  display: block;
  width: 100%;
  margin-bottom: 16px;
  font-size: var(--fs20);
  font-weight: 500;
  line-height: 26px;
  color: rgba(0, 11, 51, 80%);
}
.withdraw_group input, .withdraw_group select {
  display: block;
  width: 100%;
  border: 1px solid rgba(189, 189, 189, 0.25);
  border-radius: 10px;
  height: 48px;
  padding: 8px 34px;
  font-size: var(--fs18);
  line-height: 26px;
  font-weight: 500;
  color: var(--blue);
  outline: unset;
  appearance: none;
}
.withdraw_group select {
  background-position: calc(100% - 30px);
}
.withdraw_inner_box,.add_balance_inner {
  max-width: 80%;
  margin: 0 auto;
}
.withdraw_form > div:not(:first-child) {
  margin-top: 40px;
}
button.withdraw_sub {
  border: unset;
  margin-top: 48px;
  max-width: 100%;
  width: 100%;
  font-weight: 700;
}
/*=========================|| My Wallet Css End ||================================ */
/*=====================|| Prebooking Trips Css Start ||=========================== */
.prebooking_trips,.booking_tour,.notification,.rates_container,.city_tour,.varification {
  background-color: rgba(61, 160, 216, 2%);
}
.prebooking_box {
  display: flex;
  flex-direction: column;
  grid-gap: 40px;
}
.booking_grid {
  display: flex;
  flex-flow: wrap;
  grid-gap: 40px 28px;
}
.booking_grid > div {
  width: calc(100%/2 - 14px);
}
.prebooking_trips > div.container > form.prebooking_form,.booking_tour > div.container > form.book_tour_form {
  margin: 0 auto;
  max-width: 80%;
}
.booking_group input, .booking_group select, .booking_group textarea {
  padding: 8px 16px;
}
.booking_group select {
   background-position: calc(100% - 16px);
}
/*===========================|| Prebooking Trips Css End ||=================================== */
/*=====================|| My Preferences & Add Balance Css Start ||=========================== */
.preferences_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 16px;
  padding: 13px 30px;
  background-color: var(--white);
  border-radius: 10px;
}
.preferences_box label.switch input {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  margin: 0px;
  cursor: pointer;
  display: block;
}
.preferences_box label.switch {
  position: relative;
  display: inline-block;
  width: 38px;
  min-width: 38px;
  height: 22px;
  cursor: pointer;
}
.switch span {
  border-radius: 34px;
  display: block;
  width: 100%;
  height: 100%;
  background-color: var(--light_blue);
}
.switch span:before {
}
.switch span:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}
.switch input:checked + span {
  background-color: #2DAE72;
}
.switch input:checked + span:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}
.preferences_box > p {
  display: flex;
  grid-gap: 18px;
  align-items: center;
  font-size: var(--fs20);
  line-height: 24px;
  font-weight: 400;
}
.preferences_box > p span {
  display: flex;
  width: 29px;
  height: 29px;
  background-color: rgba(61, 160, 216, 0.06);
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}
.preferences_box > p span img {
  width: 18px;
  height: 18px;
  object-fit: contain;
}
.my_preferences > div.container {
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
}
.booking_group textarea {
  height: 150px;
}
.balance_group, .balance_group label {
  display: flex;
  align-items: center;
  grid-gap: 18px;
  width: 100%;
}
.balance_group label {   
  order: 1;
}
.balance_group {
  justify-content: space-between;
  background-color: var(--white);
  border-radius: 10px;
  padding: 15px 30px 15px 24px;
  height: 55px;
}
.balance_group label span.card_img {
  width: 44px;
}
.balance_group label span {
  width: calc(100% - 44px);
  color: var(--black);
  font-size: var(--fs20);
  line-height: 24px;
  font-weight: 400; 
  display: block;
}
.add_balance {
  background: rgba(61, 160, 216, 2%);
}
.balance_group input {
  margin: 0px;
  order: 2;
  appearance: none;
  position: relative;
}
.balance_group input::before {
  content: "";
  border: 1px solid var(--light_blue);
  width: 20px;
  height: 20px;
  display: block;
  border-radius: 50%;
}
.balance_group input::after {
  content: "";
  display: block;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMSAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwLjAyNjMgMjBDNy43MDY2NyAyMCA1LjQ1ODgyIDE5LjE5NzkgMy42NjU3MyAxNy43MzAyQzEuODcyNjQgMTYuMjYyNSAwLjY0NTI0OCAxNC4yMjAxIDAuMTkyNjg5IDExLjk1MUMtMC4yNTk4NyA5LjY4MTk0IDAuMDkwNDA0MyA3LjMyNjUzIDEuMTgzODMgNS4yODYxNEMyLjI3NzI1IDMuMjQ1NzYgNC4wNDYxOCAxLjY0NjY0IDYuMTg5MTkgMC43NjEyNTJDOC4zMzIyMSAtMC4xMjQxMzIgMTAuNzE2NyAtMC4yNDA5OTggMTIuOTM2NCAwLjQzMDU2N0MxNS4xNTYyIDEuMTAyMTMgMTcuMDczOCAyLjUyMDU4IDE4LjM2MjUgNC40NDQyMUMxOS42NTEyIDYuMzY3ODUgMjAuMjMxMyA4LjY3NzY2IDIwLjAwNCAxMC45ODAxQzE5Ljc3NjYgMTMuMjgyNSAxOC43NTU5IDE1LjQzNTEgMTcuMTE1NyAxNy4wNzFDMTUuMjM1NSAxOC45NDY0IDEyLjY4NTMgMjAgMTAuMDI2MyAyMFpNOS4wMjM2NSAxNEwxNi4xMTIxIDYuOTI5TDE0LjY5NzQgNS41MTVMOS4wMjM2NSAxMS4xNzJMNi4xOTAyNyA4LjM0M0w0Ljc3MjU4IDkuNzU3TDkuMDIzNjUgMTRaIiBmaWxsPSJ1cmwoI3BhaW50MF9saW5lYXJfMTg2M185NzE5KSIvPgo8ZGVmcz4KPGxpbmVhckdyYWRpZW50IGlkPSJwYWludDBfbGluZWFyXzE4NjNfOTcxOSIgeDE9IjEwLjY5NDUiIHkxPSIyLjQ3NTM3IiB4Mj0iLTYuMTM3MDUiIHkyPSIxMy45OTAxIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+CjxzdG9wIHN0b3AtY29sb3I9IiMzREEwRDgiLz4KPHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjM0RBMEQ4Ii8+CjwvbGluZWFyR3JhZGllbnQ+CjwvZGVmcz4KPC9zdmc+Cg==);
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  display: none;
}
.balance_group input:checked::before {
  border: unset;
  display: none;
}
.balance_group input:checked::after {
  border: unset;
  display: block;
}
.add_balance_form > div:not(:first-child) {
  margin-top: 24px;
}        
/*=========================|| My Preferences & Add Balance Css End ||============================= */
/*======================|| Register Traveler Css Start ||=========================== */
.booking_group select[multiple] {
  height: auto;
}
.checkbox-group {
  display: flex;
  align-items: center;
  align-items: baseline;
  grid-gap: 14px;
}
.checkbox-group input {
  width: fit-content;
  margin: 0px;
  height: unset;
}
.select-multiple {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 6px 16px;
  background-color: var(--white);
  border-radius: 10px;
  border: 1px solid rgba(189, 189, 189, 0.25);
}
.select-multiple button {
  background-color: #001d6c;
  color: white;
  border: none;
  border-radius: 100px;
  padding: 7px 24px;
  cursor: pointer;
  font-size: 12px;
  height: 34px;
  border: 1px solid transparent;
}
.select-multiple button.selected {
  background-color: var(--white);
  color: rgba(0, 11, 51, 80%);
  font-size: 12px;
  line-height: 20px;
  border: 1px solid rgba(189, 189, 189, 0.25);
}
.checkbox-group label {
  margin: 0px;
}
.checkbox-group label a {
  color: #3DA0D8;
}
/*========|| Form successfully Model Css start ||========*/
.successfully_popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 656px;
  height: 523px;
  box-shadow: 0px -10px 20px 0px rgba(220, 220, 220, 0.25);
  background-color: var(--white);
  z-index: 999;
  border-radius: 10px;
  padding: 40px;
}
.successfully_popup img {
  width: 161px;
  height: 158px;
  margin: 0 auto;
}
.successfully_popup h2 {
  font-size: var(--fs48);
  font-weight: 700;
  line-height: 58px;
  text-align: center;
  margin: 40px 0px 16px 0px;
}
.successfully_popup p {
  color: rgba(0, 11, 51, 80%);
  font-size: var(--fs18);
  line-height: 26px;
  font-weight: 500;
  text-align: center;
}
.successfully_popup a {
  margin: 40px auto 0px;
}
.successfully_box {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
/*========|| Form successfully Model Css End ||=========*/
/*======================|| Register Traveler Css End ||===========================*/
/*========================|| Notification Css Start ||============================*/
.notification {
  padding: var(--ptb60) 40px;
}
.notification_box_grid {
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
}
.notification_box img {
  width: 49px;
  height: 58px;
  object-fit: contain;
}
.notification_box {
  display: flex;
  flex-flow: wrap;
  grid-gap: 10px;
}
.notification_content {
  width: calc(100% - 59px);
}
.notification_grid {
  background-color: var(--white);
}
.notification_grid {
  background-color: var(--white);
  padding: 20px 60px 15px 60px;
  border-radius: 10px;
}
.notification_text h5 {
  font-size: var(--fs14);
  font-weight: 900;
  line-height: 21px;
  color: var(--blue);
}
.notification_text p {
  font-size: var(--fs18);
  font-weight: 500;
  line-height: 26px;
  color: rgba(0, 11, 51, 80%);
}
.notification_date p {
  text-align: right;
  margin-top: 16px;
}
.notification_text {
  width: calc(100% - 90px - 60px);
}
.notification_date {
  /* width: max-content; */
  /* margin-left: auto; */
}
/*=======================|| Notification Css End ||===========================*/
/*========================|| My Rates Css Start ||============================*/
.total_rates {
  background-color: var(--white);
}
.star_review {
  padding: 20px 100px;
  border-bottom: 0.7px solid #E7E7E7;
  margin-bottom: 20px;
}
.star_grid {
  padding: 0px 100px 30px 100px;
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
}
.star_review,.star_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 20px;
  flex-flow: wrap;
}
p.total_star {
  display: flex;
  align-items: center;
  grid-gap: 8px;
}
.star_review p,.star_box p {
  font-size: var(--fs20);
  font-weight: 500;
  line-height: 26px;
  color: var(--black);
}
.rates_container {
  padding: 40px 40px 15px 40px;
}
.rates_container > h5 {
  font-size: var(--fs20);
  font-weight: 500;
  line-height: 26px;
  padding-left: 40px;
  color: #377CFD;
  margin-bottom: 30px;
}
.reviewer_content .comment_contant p span img {
  height: 24px;
  width: fit-content;
  border-radius: unset;
  margin-top: 8px;
}
.reviewer_content .comment_contant {
  grid-gap: 15px;
}
.reviewer_content .comment_contant p span {
  display: block;
}
.reviewer_content {
  padding: 30px 60px;
  background-color: var(--white);
  border-radius: 10px;
}
.rates_container > div.reviewer_comments:not(:last-child) {
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid rgba(55, 124, 253, 10%)
}
/*========================|| My Rates Css End ||============================*/
/*====================|| City Tour Al-Ula Css End ||========================*/
.tourism_box > img {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  box-shadow: 0px 0px 10px 0px rgba(90, 90, 90, 0.25);
  border: 3px solid rgba(255, 255, 255, 1);
}
.tourism_box {
  display: flex;
  grid-gap: 20px;
}
.tours_detail {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  grid-gap: 10px;
}
p.tourism_review {
  display: flex;
  align-items: center;
}
.country_charges {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-gap: 12px;
}
.country_flag {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  justify-content: end;
}
.country_flag img {
  width: 32px;
  height: 32px;
}
.customer_grid {
  display: flex;
  justify-content: space-between;
}
.city_tour_grid .btn_group {
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  grid-gap: 10px 20px;
  margin-top: 40px;
}
.city_tour_grid .btn_group a {
  margin: 0px;
}
.city_tour_grid .btn_group a:hover{
  background-color:var(--black) !important;
}
.city_tour_box {
  padding: 30px 30px 20px 30px;
  background-color: var(--white);
  border-radius: 10px;
}
.city_tour_grid {
  display: flex;
  grid-gap: 30px 20px;
  flex-flow: wrap;
}
.city_tour_grid > div.city_tour_box {
  width: calc(100%/2 - 10px);
}
a.program_btn, a.booking_btn {
  margin-top: 24px;
  padding: 11px 20px;
  min-width: 260px;
  height: 48px;
  line-height: 24px;
  font-weight: 700;
}
a.booking_btn {
  background: linear-gradient(235.69deg, #3DA0D8 32.76%, #3DA0D8 106%);
}
.customer_text p {
  font-size: var(--fs20);
  font-weight: 400;
  line-height: 28px;
  margin-top: 20px;
}
.country_charges h4 {
  font-size: var(--fs20);
  line-height: 24px;
  font-weight: 700;
  color: #3DA0D8;
}

.tours_detail h5 {
  font-size: var(--fs18_);
  line-height: 22px;
  font-weight: 700;
}
.tours_detail p,.tours_detail p.tourism_review span.star_count {
  font-size: var(--fs18);
  font-weight: 700;
  line-height: 22px;
  margin: 0px 8px;
  color: var(--black1);
}
.tours_detail p {
  font-weight: 400;
  color: var(--light_blue);
}
/*=====================|| City Tour Al-Ula Css End ||========================*/
/*=====================|| My Trip Start ||========================*/
.my_trip{
  background-color: var(--light);
}
.my_trip .container{
  display: flex;
  flex-flow: wrap;
  grid-gap: 60px;
}
.trip_box {
  border-radius: 20px;
  background-color: var(--white);
  padding: 18px 32px;
  width: calc(100%/2 - 30px);
  position: relative;
}
.trip_head {
  display: flex;
  align-items: flex-end;
  grid-gap: 20px 30px;
}
.trip_head h2 {
  color: var(--black);
  font-size: 19px;
  font-weight: bold;
}
.trip_head span {
  font-weight: 500;
}
.trip_head img {
  height: 20px;
  width: 68px;
  display: block;
  object-fit: contain;
}
ul.trip_list {
  list-style: none;
  padding: 0px;
  margin: 26px 0px;
  display: flex;
  flex-flow: wrap;
  grid-gap: 17px;
}
ul.trip_list li {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 19px auto;
  font-size: 18px;
  color: var(--black);
  font-weight: 400;
  width: 100%;
  position: relative;
}
ul.trip_list li::before{
  content:"";
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(../../public/images/my_trip/line1.png);
  display: block;
  width: 2px;
  height: 21px;
  position: absolute;
  left: 8.5px;
  top: 23px;
}
ul.trip_list li:last-child::before{
  display:none;
}
.trip_bottom {
  display: flex;
  align-items: center;
  flex-flow: wrap;
  justify-content: space-between;
}
.trip_bottom p {
  color: #ABABAB;
  font-size: 15px;
}
.trip_bottom h3 {
  font-size: 22px;
  color: var(--sky_blue);
  font-weight: 600;
}
.ribben {
  width: 71.36px;
  height: 73px;
  position: absolute;
  right: -6px;
  top: -4px;
}
.ribben::before {
  content: "";
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height:100%;
}
.ribben span {
  display: block;
  position: absolute;
  top: calc(50% - 9px);
  left: calc(50% + 11px);
  transform: translate(-50%,-50%) rotate(45deg);
  font-size: 12px;
  font-family: 'Tajawal';
  color: var(--white);
}
.ribben.sky::before{
  background-image: url('../../public/images/my_trip/ribben.png');
}
.ribben.yellow::before{
  background-image: url('../../public/images/my_trip/ribbeny.png');
}
ul.captan_list {
  margin: 0px;
  list-style: none;
  padding: 0px;
  display: flex;
  grid-gap: 13.3px;
  margin-top: 30px;
  flex-flow: wrap;
}
.city_tour .container {
  display: flex;
  flex-flow: wrap;
  grid-gap: 20px;
}
.city_tour_box:nth-child(1),.city_tour_box:nth-child(4){
  width: 100%;
}
.city_tour_box {
  padding: 30px 30px 20px 30px;
  background-color: var(--white);
  border-radius: 10px;
  width: calc(100% / 2 - 26px);
}
.city_tour_box .trip_right h3,.city_tour_box h3 {
  font-size: 22px;
  color: var(--black);
  margin-bottom: 15px;
}
.city_tour_box .trip_right h4 {
  font-size: 18px;
  color: var(--black);
}
.city_tour_box .trip_right p {
  color: #94A3B8;
  font-size: 18px;
  margin-top: 7px;
}
.city_tour_box .trip_head {
  align-items: center;
}
.city_tour_box .trip_head img {
  width: 137px;
  height: 76px;
}
.city_tour_box .term_heading {
  margin-bottom: 30px;
}
.city_tour_box .term_heading:nth-child(2){
  margin-top: 35px;
}
/*=====================|| My Trip End ||========================*/
/*==================|| Redesign Css Start ||====================*/
.screen_owl .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  -webkit-backface-visibility: visible;
  transition: opacity .2s ease;
  border-radius: 30px;
  background-color: var(--bg_blue);
  border: 2px solid var(--black);
  transition: all 0.3s;
  opacity: 0.5;
}
.screen_owl .swiper-pagination-bullet-active {
 background-color:var(--blue);
 opacity:1
}

form.varification_form {
  padding: 16px 40px;
  background-color: var(--white);
  border-radius: 10px;
}
.proof_contact {
  display: flex;
  grid-gap: 10px;
  align-items: center;
}
.proof_contact span {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #3DA0D80F;
  width: 40px;
  height: 40px;
}

.proof_contact span img {
  width: 22px;
  height: 22px;
  object-fit: contain;
}
.varification_proof {
  display: flex;
  justify-content: space-between;
  grid-gap: 24px;
  align-items: center;
}
.varification_grid {
  display: flex;
  flex-direction: column;
  grid-gap: 30px;
}

.varification_grid > p {
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
}
.varification_proof .num_varify, .varification_proof .email_varify, .varification_proof .id_card {
  margin: 0px;
  height: fit-content;
  appearance: none;
  position: relative;
  width: 104px;
  text-align: end;
  display: flex;
  justify-content: end;
}
.varification_proof .num_varify::before, .varification_proof .email_varify::before, .varification_proof .id_card::before {
  content: "";
  width: 20px;
  height: 20px;
  display: block;
  background-image: url("../../public/images/green-right.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  /* width: 56px; */
  text-align: end;
  font-size: var(--fs14);
  line-height: 20px;
  font-weight: 400;
  color: var(--white);
}
.varification_proof .id_card::before {
  content: "activation";
  width: fit-content;
  text-align: end;
  background-image: url();
  display: block;
  padding: 8px 16px;
  border-radius: 100px;
  cursor: pointer;
  background: linear-gradient(235.69deg, #3DA0D8 32.76%, #3DA0D8 106%);
}
.contact_active p strong {
  font-size: var(--fs18);
  line-height: 21px;
  font-weight: 500;
  color: #181D2C;
  margin-bottom: 8px;
  display: block;
}

.contact_active p a {
  font-size: 19px;
  line-height: 21px;
  font-weight: 400;
  color: #94A3B8;
  display: block;
}


.pagination button {
   background-color: #ffffff;
   border: 1px solid #000B33;
   color: rgb(0, 0, 0);
   padding: 8px 16px;
   text-align: center;
   text-decoration: none;
   display: inline-block;
   font-size: 16px;
   font-weight: bold;
   margin: 4px 20px;
   transition-duration: 0.4s;
   cursor: pointer;
   border-radius: 50%;
 }
 
 .pagination button.active {
   background-color: #222D61;
   color: white;
 }
 
 .pagination button:hover {
   background-color: #222D61;
   color: white;
 }
 