.testimonial_owl {
  position: relative;
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  bottom: 20px;
  width: 34px;
  height: 34px;
  background-color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #333333;
  z-index: 10;
}

.swiper-button-prev::after {
font-size: 24px !important;
}
.swiper-button-next::after {
  font-size: 24px !important;
}

.swiper-button-prev {
  left: 0px !important;
  top: 350px !important;
  background-color: #212d62;
  width: 40px !important;
  height: 40px !important;
  border-radius: 5px !important;
  color: #ffffff !important;
  font-size: 10px !important;
  size: 10px !important;
}

.swiper-button-next {
  right: 470px !important;
  top: 350px !important;
  background-color: #212d62;
  width: 40px !important;
  height: 40px !important;
  border-radius: 5px !important;
  color: #ffffff !important;
  font-size: 10px !important;

}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
