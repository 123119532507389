@media(max-width:1280px) {
    :root {
        --ptb120: 80px;
        --ptb100: 60px;
        --ptb90: 80px;
        --ptb80: 50px;
        --ptb60: 40px;
        --fs70: 54px;
        --fs60: 60px;
        --fs48: 36px;
        --fs40: 32px;
        --fs18: 16px;
        --fs16: 15px;
        --fs14: 14px;
    }
    .header_navbar {
        grid-gap: 20px 30px;
    }
    ul.header_menu {
        grid-gap: 16px;
    }
    .header .container {
        grid-gap: 16px;
    }
    ul.login_box {
        grid-gap: 20px 20px;
    }
    .blue_btn a, .img_contant a, .card_details a {
        padding: 11px 20px;
    }
    .hero_inner {
        padding: 40px 0px;
        max-width: 510px;
    }
    .hero_inner h1 {
        margin: 0px 0px 16px 0px;
        line-height: 60px;
    }
    .hero_img {
        transform: translateY(48px);
        width: 590px;
    }
    .hero {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom center;
        position: relative;
        z-index: 3;
        width: 100%;
        min-height: 560px;
    }
    .hero_img {
        transform: translateY(48px);
        width: 480px;
    }
    .hero_img img {
        height: 560.06px;
    }
    .sub_content h4 {
        line-height: 24px;
        margin-top: 20px;
    }
    .sub_content p {
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
    }
    .about_inner .sec_head, .traveler .container .about_img {
        width: calc(100% - 640px - 60px);
    }
    .about_inner .about_img {
        width: 640px;
        height: fit-content;
    }
    .img_contant h3 {
        font-size: 24px;
        line-height: 1.25 !important;
    }
    .img_contant p {
        font-size: 16px;
        margin: 10px 0px 24px;
    }
    .img_contant {
        top: calc(50% - 40px);
        transform: translateY(-50%);
        left: 14%;
    }
    .about_img > a {
        left: calc(50% + 20px);
        top: calc(50% - 10px);
        transform: translate(-50%, -50%);
    }
    .about_img a img {
        width: 60px;
        height: 60px;
    }
    .traveler .container .sec_head {
        width: calc(100% - 42% - 60px);
    }
    .traveler .container .about_img {
        width: calc(100% - 58% - 60px);
    }
    .banner_head h1 {
        line-height: 60px;
    }
    h2.box_heading {
        line-height: 42px;
        margin: 40px 0px 16px 0px;
    }
    .forget_act .account_left {
        min-height: 530px;
    }
    .otp_input {
        width: 80px;
    }
    .verify_code .account_left {
        min-height: 764px;
    }
    .forget_act .account_left {
        min-height: 810px;
    }
    .travel_box::before {
        height: 120px;
    }
    .news_list {
        padding: 30px;
        width: 360px;
    }
    .news_details {
        width: calc(100% - 360px - 24px);
    }
    .single_news .list-wrapper .list-item,.single_news .pagination ul,.client_comments, .review_box {
        width: 90%;
        max-width: 90%;
    } 
    .term_condition {
        padding: 80px 0px;
    }
    .term_condition > div.container > div {
        padding: 50px 80px 40px 80px;
    }
    .edit_box {
        margin-bottom: 50px;
    }   
    .edit_grid > div {
        width: calc(100%/2 - 40px);
    }
    .login_account .form_group .password_input {
        padding: 12px 60px 12px 34px;
    }
    .balance_grid {
        grid-gap: 30px 40px;
    }
    .balance_grid > div {
        width: calc(100%/2 - 20px);
    }
    a.Withdraw_btn, a.add_btn {
        margin-top: 16px;
    }
    .my_wallet .container .wallet_bal h2 {
        line-height: 36px;
    }
    .edit_sub,.prebooking_sub {
        margin-top: 40px;
    }
    .notification_grid {
        padding: 20px 50px 15px 50px;
    }
    .comment_contant > p {
        line-height: 22px;
    }
    .my_trip .container {
        grid-gap: 40px;
    }
    .trip_box {
        width: calc(100% / 2 - 20px);
    }
    .city_tour_grid .btn_group a {
        min-width: calc(100% / 2 - 10px);
    }
    .city_tour_box {
        padding: 20px;
    }
}
@media(max-width:1024px) {
    :root {
        --ptb120: 60px;
        --ptb100: 60px;
        --ptb90: 60px;
        --ptb80: 48px;
        --ptb60: 40px;
        --fs70: 42px;
        --fs60: 40px;
        --fs48: 32px;
        --fs40: 30px;
        --fs30: 28px;
        --fs18: 16px;
        --fs16: 14px;
        --fs14: 13px;
    }
    .blue_btn a, .img_contant a, .card_details a {
        padding: 8px 20px;
        margin-top: 30px;
    }
    .hero_inner h1 {
        line-height: 48px;
    }
    .coming .container .offer_content {
        margin-top: 30px;
        width: 42%;
    }
    .about_inner .sec_head, .traveler .container .about_img {
        width: calc(100% - 55% - 60px);
    }
    .about_inner .about_img {
        width: 55%;
    }
    .traveler .container .sec_head {
        width: calc(100% - 42% - 60px);
    }
    .traveler .container .about_img {
        width: calc(100% - 52% - 60px);
        grid-gap: 40px;
    }
    .counter_details h3 {
        line-height: 54px;
    }
    .testimonial_owl .item p {
        max-width: 100%;
    }
    .contact_box {
        padding: 40px;
    }
    .banner_head h1 {
        line-height: 48px;
    }
    .banners > div.container {
        grid-gap: 30px;
    }
    .whts_icon {
        right: 48px;
        width: 48px;
        height: 48px;
    }
    .whts_icon a, .whts_icon a img {
        width: 32px;
    }
    .our_team .team_inner {
        grid-gap: 20px;
    }
    .team_inner > div.card_box {
/*        width: calc(100%/4 - 15px);*/
        padding: 16px 16px 20px 16px;
    }
    .team_inner > div.card_box .card_details {
        padding: 24px 12px 0px 12px;
    }
    .tourism_tours > div > div.tourism_inner {
        max-width: 90%;
    }
    .tourism_tours.our_team.gallery_team .card_box .card_details h3 {
        font-size: 24px;
        line-height: 32px;
    }
    .account_content > img {
        max-height: 70px;
    }
    h2.box_heading {
        line-height: 36px;
    }
    .my_account.login .account_left {
        min-height: 750px;
    }
    .new_pswd .account_left {
        min-height: 660px;
    }
    .account_inner .account_left {
        width: calc(100% - 480px);
    }
    .account_create .account_left {
        min-height: 1228px;
    } 
    .forget_act .account_left {
        min-height: 540px;
    } 
    .account_content {
        width: 480px;
        padding: 60px 40px;
    }
    .login_account .form_group .phone_box input {
        padding: 16px 24px;
    }
    .login_account .form_group input, .login_account .form_group select {
/*        height: 60px;*/
    }
    .passwrd_btn {
        margin: 30px 0px 0px 0px;
    }
    .varify_btn {
        margin: 0px 0px 30px 0px;
    }
    .forget_pwrd h2.box_heading {
        margin-bottom: 12px;
    }
    .forget_pwrd p {
        margin-bottom: 28px;
    }
    .otp_inputs {
        margin: 30px 0px;
    }   
    .verify_code .account_left {
        min-height: 590px;
    }
    .faq_Navigation>div.container {
        grid-gap: 60px;
    }
    .faq_Navigation>div.container>div.topic_details {
        width: calc(100% - 260px - 60px);
    }
    .topics_box {
        width: 260px;
    }
    .topics_box h3 {
        font-size: 24px;
        line-height: 32px;
        padding: 26px 52px 26px 30px;
    }
    ul.faq_topics {
        padding: 30px 24px;
    }
    ul.faq_topics li a {
        padding: 10px 0px 10px 0px;
    }
    ul.faq_topics li:first-child a {
        padding: 0px 0px 10px 0px;
    }
    ul.faq_topics li:last-child a {
        padding: 10px 0px 0px 0px;
    }
    .single_news .list-wrapper .list-item,.single_news .pagination ul,.client_comments, .review_box {
        width: 100%;
        max-width: 100%;
    }
    .single_news .list-wrapper .news_img img {
        height: 400px;
    }
    .review_box {
        padding: 30px 30px 30px 30px;
        margin: 40px auto 0px auto;
    }
    .client_comments > h4 {
        padding-left: 10px;
    }
    .term_condition {
        padding: 60px 0px;
    }
    .term_condition > div.container > div {
        padding: 40px 60px 30px 60px;
    }
    .term_heading h2 {
        line-height: 36px;
    }
    .edit_box {
        margin-bottom: 40px;
    }
    .edit_grid {
        grid-gap: 60px;
    }
    .edit_grid > div {
        width: calc(100%/2 - 30px);
    }
    .edit_profile .form_edit select {
        width: 90px;
        min-width: 90px;
    }
    .form_edit .phone_box input {
        width: calc(100% - 90px);
    }
    .edit_grid {
        margin-bottom: 30px;
    }
    .edit_sub,.prebooking_sub {
        margin-top: 30px;
    }
    .edit_avatar {
        margin-bottom: 40px;
    }
    .balance_grid {
        grid-gap: 20px 30px;
    }
    .balance_grid > div {
        width: calc(100%/2 - 15px);
    }
    .add_box, .withdraw_box {
        grid-gap: 24px 30px;
    }
    a.Withdraw_btn, a.add_btn {
        padding: 10px 24px;
        margin-top: 16px;
    }
    .wallet_history > div.add_blnc {
        padding: 0px 24px 16px 24px;
    }
    .my_wallet .container .wallet_bal img {
        max-width: 120px;
    }
    .my_wallet .container .wallet_bal h2 {
        line-height: 36px;
    }
    .withdraw_bal h4 {
        margin: 30px 0px 16px 0px;
    }
    .withdraw_form > div:not(:first-child) {
        margin-top: 30px;
    }
    .add_balance_form > div:not(:first-child) {
        margin-top: 20px;
    }
    .withdraw_group label {
        margin-bottom: 16px;
    }
    button.withdraw_sub {
        margin-top: 40px;
    }
    .counter .container>div {
        grid-gap: 24px 24px;
    }
    h2.box_heading {
        margin: 30px 0px 16px 0px;
    }
    .prebooking_box {
        grid-gap: 30px;
    }
    .prebooking_trips > div.container > form.prebooking_form,.booking_tour > div.container > form.book_tour_form {
        max-width: 90%;
    }
    .form_group textarea,.booking_group textarea {
        height: 120px;
    }
    .successfully_popup a {
        margin: 30px auto 0px;
    }
    .notification_grid {
        padding: 20px 40px 15px 40px;
    }
    .notification_date p {
        margin-top: 12px;
    }
    .notification_text {
        width: calc(100% - 90px - 40px);
    }
    .notification {
        padding: var(--ptb60) 30px;
    }
    .star_review {
        padding: 20px 60px;
        margin-bottom: 20px;
    }
    .reviewer_content .comment_contant {
        grid-gap: 12px;
    }
    .star_grid {
        padding: 0px 60px 30px 60px;
        grid-gap: 20px;
    }
    .rates_container > h5 {
        padding-left: 10px;
        margin-bottom: 20px;
    }
    .reviewer_content {
        padding: 30px 40px;
    }
    .rates_container > div.reviewer_comments:not(:last-child) {
        padding-bottom: 30px;
        margin-bottom: 30px;
    }
    .my_trip .container {
        grid-gap: 30px;
    }
    .trip_box {
        width: calc(100% / 2 - 15px);
    }
}
@media(max-width:991px) {
    :root {
        --ptb120: 60px;
        --ptb100: 60px;
        --ptb90: 50px;
        --ptb80: 40px;
        --ptb60: 30px;
        --fs70: 48px;
        --fs60: 36px;
        --fs48: 30px;
        --fs40: 28px;
        --fs30: 24px;
        --fs18: 16px;
        --fs16: 14px;
        --fs14: 14px;
    }
    .logo img {
        height: 48px;
    }
    .hero .container {
        grid-gap: 0px;
        flex-flow: wrap;
    }
    .hero_inner {
        padding: 40px 0px 0px;
        max-width: 100%;
    }
    .hero_img {
        transform: translateY(40px);
        width: 100%;
    }
    .hero_img img {
        height: 460px;
        object-position: center;
        width: 100%;
        object-fit: contain;
    }
    .modal {
        width: 80%;
    }
    .service_inner > div.service_box {
        width: calc(100% / 2 - 10px);
    } 
    .offer_img img {
        max-height: 380px;
        width: 100%;
        object-fit: contain;
        /* object-position: left; */
    }
    .about_inner:nth-child(even) > div {
        flex-flow: column-reverse;
    }
    .coming .container {
        grid-gap: 40px 60px;
        flex-flow: wrap;
    }
    .coming .container > div,.coming .container .offer_content{
        width: 100%;
        margin-top: 0px;
    }
    .about_inner .sec_head, .traveler .container .about_img,.about_inner .about_img,.traveler .container .sec_head {
        width: 100%;
    }
    .traveler .container .about_img {
        grid-gap: 30px;
    }
    .about_img > a {
        left: calc(48% + 20px);
        transform: translate(-48%, -50%);
    }
    .sec_head h2 {
        line-height: 42px;
        margin-bottom: 10px;
    }
    .about_inner .container, .traveler .container {
        grid-gap: 30px 60px;
    }
    .card_details h3 {
        font-size: 20px;
    }
    .card_details p {
        font-size: 16px;
        line-height: 20px;
    }
    .card_inner {
        grid-gap: 30px 24px;
        max-width: 95%;
    }
    .card_inner > div {
        width: calc(100% / 3 - 16px);
    }
    .counter .container {
        display: flex;
        justify-content: space-between;
        grid-gap: 24px;
        align-items: center;
    }
    .counter .container > div {
        width: calc(100% / 4 - 12px);
        display: flex;
        flex-flow: wrap;
        grid-gap: 20px 20px;
        align-items: center;
    }
    .counter_details h3 {
        line-height: 48px;
    }
    .footer_grid > div.footer_logo {
        width: 100%;
    }
    .copyright {
        padding: 24px 0px;
    }
    .contact_box {
        padding: 30px;
    }
    .contact_box a br {
        display: none;
    }
    .whts_icon {
        right: 32px;
    }
    .team_inner > div.card_box {
        width: calc(100%/3 - 13.33px);
    }
    /*-------------|| Header Menu Css start ||-------------------*/
     ul.header_menu {
        position: fixed;
        display: block;
        width: 100%;
        top: 0px;
        left: 0;
        transform: translateX(-100%);
        transition: all 0.3s linear;
        background: linear-gradient(90.28deg, #001C80 0.26%, #153ABF 30.33%, #2B59FF 99.79%);
        padding: 85px 16px 0px 16px;
        height: 100vh;
    }
    ul.header_menu li a {
        display: block;
        padding: 8px 0px;
        border-bottom: 1px solid rgba(189, 189, 189, 0.25);
    }
    .toggle_grid {
        display: flex;
        align-items: center;
        grid-gap: 8px;
    }
    .toggle_btn {
        display: block;
    }
    header.show ul.header_menu {
        transform: translateX(0%);

    }
    /*---------------|| Header Menu Css End ||-------------------*/
    .tourism_tours > div > div.tourism_inner {
        max-width: 100%;
    }
    .my_account.login .account_left {
        min-height: 700px;
    }
    .account_inner .account_left {
        width: calc(100% - 400px);
    }
    .account_create .account_left {
        min-height: 1188px;
    }
    .new_pswd .account_left {
        min-height: 660px;
    }
    .forget_act .account_left {
        min-height: 386px;
    }
    .account_content {
        width: 400px;
        padding: 40px 30px;
    }
    h2.box_heading {
        line-height: 36px;
    }
    .forget_pwrd p {
        margin-bottom: 24px;
    }
    .otp_input {
        width: 70px;
    }
    .forget_act .account_left {
        min-height: 510px;
    }
    .verify_code .account_left {
        min-height: 540px;
    }
    .travel_box::before {
        height: 106px;
    }
    ul.tabs_links {
        grid-gap: 30px 30px;
    }   
    ul.tabs_links li a {
        padding: 10px 24px;
    }
    .faq_Navigation>div.container {
        grid-gap: 40px;
    }
    .faq_Navigation>div.container>div.topic_details {
        width: calc(100% - 260px - 40px);
    }
    .news_list {
        width: 100%;
    }
    .news_details {
        width:100%;
    }
    .recent_news {
        width: calc(100% / 2 - 0px);
        float: left;
    }
    .Comments_list > div.Comments_details, .reviewer_content {
        padding: 20px 20px 20px 20px;
        grid-gap: 20px;
    }
    .comment_contant {
        width: calc(100% - 80px - 20px);
        grid-gap: 12px;
    }
    .comment_diveder {
        margin: 20px 0px;
    }
    .term_condition > div.container > div {
        padding: 40px 40px 30px 40px;
    }
    .term_heading h2 {
        line-height: 30px;
    }
    .edit_profile .form_edit select {
        width: 80px;
        min-width: 80px;
    }
    .form_edit .phone_box input {
        width: calc(100% - 80px);
    }
    .edit_grid {
        grid-gap: 40px;
    }
    .edit_grid > div {
        width: calc(100%/2 - 20px);
    }
    .profile_box {
        max-width: 90%;
    }
    .wallet_history > div.add_blnc {
        padding: 0px 20px 16px 20px;
    }
    .add_blnc h5 {
        margin-bottom: 8px;
        line-height: 24px;
    }
    .my_wallet .container .wallet_bal img {
        max-width: 100px;
    }
    .withdraw_bal h4 {
        margin: 24px 0px 16px 0px;
    }
    .withdraw_inner_box,.add_balance_inner {
        max-width: 90%;
    }
    .successfully_popup {
        width: 520px;
        height: 490px;
        padding: 30px;
    }
    .successfully_popup img {
        width: 130px;
        height: 127px;
    }
    .successfully_popup h2 {
        line-height: 32px;
        margin: 30px 0px 16px 0px;
    }
    .notification_grid {
        padding: 15px 30px 15px 30px;
    }
    .notification_text {
        width: calc(100% - 90px - 20px);
    }
    .star_grid {
        padding: 0px 40px 30px 40px;
        grid-gap: 16px;
    }
    .star_review {
        padding: 16px 40px;
        margin-bottom: 16px;
    }   
    ul.trip_list li {
        font-size: 16px;
    }
    .trip_head h2 {
        font-size: 18px;
    }
    .trip_bottom h3 {
        font-size: 20px;
    }
    .my_trip .container {
        grid-gap: 20px;
    }
    .trip_box {
        width: calc(100% / 2 - 10px);
    }
    .city_tour_grid > div.city_tour_box {
        width: 100%;
    }
    .city_tour_box {
        width: 100%;
    }
    .city_tour_box .term_heading:nth-child(2) {
        margin-top: 25px;
    }
    .city_tour_box .term_heading {
        margin-bottom:20px;
    }
}
@media(max-width:767px) {
    :root {
        --ptb120: 50px;
        --ptb100: 40px;
        --ptb90: 36px;
        --ptb80: 32px;
        --ptb60: 30px;
        --fs70: 36px;
        --fs60: 32px;
        --fs48: 28px;
        --fs40: 26px;
        --fs18: 16px;
    }
    .sec_head h2 {
        line-height: 36px;
    }
    .modal {
        width: 90%;
    }
    .modal video {
        height: 380px;
    }
    .app_store {
        grid-gap: 16px;
        margin-top: 30px;
    }
    .app_store a img {
        height: 40px;
    }
    .card_inner > div {
        width: calc(100% / 2 - 12px);
    }
    .card_details h3 {
        font-size: 18px;
    }
    .counter .container {
        flex-flow: wrap;
    }
    .counter .container > div {
        width: calc(100% / 2 - 12px);
        grid-gap: 20px 20px;
    }
    .testimonial .container {
        flex-flow: wrap;
        grid-gap: 30px;
    }
    .testimonial .container > div {
        width: 100%;
    }
    .footer_logo p {
        margin: 16px 0px 24px;
    }
    .contact_grid > div.contact_box {
        width: calc(100% / 2 - 12px);
    }
    .form_map > div {
        width: calc(100% / 1 - 0px);
    }
    .map_box iframe {
        height: 360px;
    }
    .contact_form {
        padding: 30px 24px;
    }
    .whts_icon a, .whts_icon a img {
        width: 24px;
    }
    .whts_icon {
        right: 24px;
    }
    .team_inner > div.card_box {
        width: calc(100%/2 - 10px);
    }
    .our_team.gallery_team .card_box .card_details {
        left: 16px;
    }
    .our_team.gallery_team .card_box:hover .card_details {
        bottom: 16px;
    }
    .account_inner .account_left {
        display: none;
    }
    .account_content {
        width: 100%;
        padding: 40px 16px;
    }
    .remember_box input {
        max-width: 16px;
        margin: 0px 0px 0px 0px;
        height: fit-content;
        height: unset !important;
    }
    .forget_act .account_content>p, .new_pswd .account_content>p, .verify_code .account_content>p {
        margin-bottom: 24px;
    }
    .login_account .form_group label {
        margin-bottom: 12px;
    }
    .login_account .remember_box label {
        margin: 0px;
    }
    h2.box_heading {
        line-height: 32px;
        margin: 24px 0px 12px 0px;
    }
    .login_account .form_group .phone_box input {
        padding: 12px 24px;
    }
    .login_account .form_group input, .login_account .form_group select {
/*        height: 52px;*/
    }
    .login_button {
        margin-bottom: 30px;
        margin-top: 30px;
    }
    .passwrd_btn {
        margin: 24px 0px 0px 0px;
    }
    .varify_btn {
        margin: 0px 0px 24px 0px;
    } 
    ul.tabs_links {
        grid-gap: 16px 12px;
        flex-flow: wrap;
    }
    ul.tabs_links li a {
        padding: 10px 12px;
    }
    .topics_box,.faq_Navigation>div.container>div.topic_details {
        width: 100%;
    }
    .faq_Navigation>div.container {
        grid-gap: 0px;
    }
    ul.faq_topics {
        padding: 30px 0px;
    }
    .news_list {
        padding: 24px;
    }
    .client_comments, .review_box {
        padding: 16px 16px 16px 16px;
    }
    .client_comments > h4 {
        padding-left: 4px;
    }
    .Comments_list > div.Comments_details,.reviewer_content {
        padding: 10px;
        grid-gap: 16px;
    }
    .comment_diveder {
        margin: 16px 0px;
    }
    .Comments_details img {
        width: 60px;
        height: 60px;
    }
    .comment_contant {
        width: calc(100% - 60px - 16px);
        grid-gap: 10px;
    }
    form#comment_form textarea {
        padding: 16px;
        height: 160px;
    }
    .term_condition > div.container > div {
        padding: 24px 24px 24px 24px;
    }
    .term_condition {
        padding: 40px 0px;
    }
    .edit_grid {
        grid-gap: 24px;
        margin-bottom: 24px;
    }
    .edit_grid > div {
        width: calc(100%/2 - 12px);
    }
    .selection_box > label {
        margin-bottom: 16px;
    }
    .form_edit label, .selection_box > label {
        margin-bottom: 12px;
    }
    .edit_avatar {
        margin-bottom: 30px;
    }
    .balance_grid > div {
        width: calc(100%/1 - 0px);
    }
    .balance_grid {
        grid-gap: 40px 30px;
    }
    a.Withdraw_btn, a.add_btn {
        margin-top: 6px;
    }
    .my_wallet .container .wallet_bal h2 {
        line-height: 32px;
    }
    .withdraw_form > div:not(:first-child) {
        margin-top: 24px;
    }
    button.withdraw_sub {
        margin-top: 30px;
    }
    .withdraw_inner_box,.add_balance_inner {
        max-width: 100%;
    }
    .withdraw_group select {
        background-position: calc(100% - 24px);
    }
    .withdraw_bal h4 {
        margin: 20px 0px 10px 0px;
    }
    .otp_inputs {
        margin: 24px 0px;
    }
    .prebooking_trips > div.container > form.prebooking_form,.booking_tour > div.container > form.book_tour_form {
        max-width: 100%;
    }
    .form_edit label, .selection_box > label, .booking_group label {
        margin-bottom: 12px;
    }
    .booking_grid {
        grid-gap: 40px 20px;
    }
    .booking_grid > div {
        width: calc(100%/2 - 10px);
    }
    .form_edit input, .form_edit select, .booking_group input, .booking_group select {
        padding: 8px 16px;
    }
    .preferences_box {
        padding: 13px 20px;
    }
    .my_preferences > div.container {
        grid-gap: 20px;
    }
    .notification_text {
        width: calc(100% - 0px - 0px);
    }
    .notification_date p {
        text-align: left;
    }
    .notification_grid {
        padding: 15px 20px 15px 20px;
    }
    .notification {
        padding: var(--ptb60) 16px;
    }
    .rates_container {
        padding: 30px 16px 15px 16px;
    }
    .rates_container > h5 {
        padding-left: 0px;
        margin-bottom: 16px;
    }
    .star_grid {
        padding: 0px 16px 30px 16px;
    }
    .star_review {
        padding: 16px 16px;
    }
    .trip_box {
        width: 100%;
    }
    form.varification_form {
        padding: 16px 24px;
    }
    .varification_grid {
        grid-gap: 24px;
    }
}
@media(max-width:575px) {
    :root {
        --ptb120: 40px;
        --ptb100: 40px;
        --ptb90: 40px;
        --ptb80: 30px;
        --ptb60: 24px;
        --fs70: 32px;
        --fs60: 28px;
        --fs48: 24px;
        --fs30: 20px;
        --fs20: 18px;
        --fs18: 16px;
        --fs16: 14px;
        --fs14: 13px;
    }
    br {
        display: none;
    }
    .ham {
        max-width: 42px;
    }
    .logo img {
        height: 42px;
    }
    ul.login_box {
        grid-gap: 20px 10px;
    }
    .login_box li a.language {
        grid-gap: 6px;
    }
    ul.header_menu {
        padding: 80px 16px 0px 16px;
    }
    .hero_img {
        transform: translateY(30px);
    }
    .hero_inner h1 {
        line-height: 38px;
    }
    .sec_head h2 {
        line-height: 28px;
    }
    .hero_img img {
        height: 380px;
    }
    .service_inner > div.service_box {
        width: calc(100% / 1 - 0px);
    }
    .travel_box span {
        width: 60px;
        height: 60px;
    }
    .travel_box::before {
/*        height: 100px;*/
        top: 60px;
        left: 30px;
    }
    .travel_box div {
        width: calc(100% - 75.5px - 25px);
    }
    .card_inner > div {
        width: calc(100% / 1 - 0px);
    }
    .footer_menu h4 {
        margin-bottom: 20px;
    }
    ul.footer_links li a {
        font-size: 16px;
        line-height: 20px;
    }
    .footer_grid {
        grid-gap: 30px;
    }
    .footer_grid > div {
        width: 100%;
    }  
    .copyright {
        padding: 20px 0px;
    }
    .img_contant {
        display: none;
    }
    .screen_owl .item {
        max-height: 330px;
    }
    .contact_grid > div.contact_box {
        width: calc(100% / 1 - 0px);
    }
    .map_box iframe {
        height: 320px;
    }
    .form_group input, .form_group textarea, .submit_btn input {
        height: 42px;
    }
    .submit_btn input {
        padding: 9px 33px;
    }
    .form_group textarea,.booking_group textarea {
        height: 100px;
    }
    .banner_head p {
        line-height: 22px;
    }
    .banners > div.container {
        grid-gap: 24px;
    }
    .our_team .team_inner {
        grid-gap: 16px;
    }
    .team_inner > div.card_box {
        width: calc(100%/1 - 0px);
    }
    .counter .container > div img {
        width: 48px;
    }
    .account_content > img {
        max-height: 60px;
    }
    .login_account .form_group {
        margin-bottom: 20px;
    }
    .login_button {
        margin-top: 30px;
    }
    .forget_pwrd h2.box_heading {
        margin-bottom: 6px;
    }
    .forget_pwrd p {
        margin-bottom: 16px;
    }
    .accordion-content {
        display: none;
        padding: 20px 20px 20px;
    }   
    .accordion-header {
        padding: 20px 20px;
    }
    .forget_act .account_content>p, .new_pswd .account_content>p, .verify_code .account_content>p {
        margin-bottom: 24px;
    }
    h2.box_heading {
        margin: 20px 0px 12px 0px;
    }
    .our_team.gallery_team .card_box img {
        max-height: 320px;
        object-fit: cover;
    }
    .recent_news,.list-wrapper .list-item {
        width: 100%;
        float: unset;
    } 
    .pagination ul {
        padding: 0px;
        grid-gap: 16px;
        justify-content: center;
    } 
    .comment_contant {
        width: 100%;
    }
    .condition_contents > div:not(:last-child) {
        margin-bottom: 24px;
    }
    .term_heading h2 {
        line-height: 26px;
        margin-bottom: 12px;
    }
    .form_group input, .form_group textarea, .submit_btn input, .form_edit input {
        padding: 8px 20px;
    }
    .avatar_img, .edit_img {
        width: 80px;
        height: 80px;
    }
    .avatar_inner, .edit_inner {
        width: 80px;
        height: 80px;
    }
    .upload_btn, .edit_btn {
        padding: 4px;
    }
    .upload_btn img, .edit_btn img {
        width: 16px;
        height: 16px;
    }
    .edit_grid > div {
        width: calc(100%/1 - 0px);
    }
    .edit_grid {
        grid-gap: 20px;
        margin-bottom: 20px;
    }
    .form_edit label, .selection_box > label {
        margin-bottom: 16px;
    }
    .gender_option {
        padding: 10px 20px;
        height: 48px;
    }
    .gender_option img {
        width: 30px;
        height: 30px;
    }
    .profile_box {
        max-width: 100%;
    }
    .my_wallet .container .wallet_bal img {
        max-width: 80px;
    }
    .my_wallet {
        padding: 30px  0px 16px 0px;
    }
    .login_account .form_group .remember_box input {
        margin: 5px 0px 0px 0px;
    }
    .prebooking_box {
        grid-gap: 24px;
    }
    .booking_grid {
        grid-gap: 24px 20px;
    }
    .booking_grid > div {
        width: calc(100%/1 - 0px);
    }
    .balance_group {
        padding: 12px 16px 12px 16px;
        height: 50px;
    }
    .balance_group, .balance_group label {
        grid-gap: 16px;
    }
    .balance_group label span.card_img {
        width: 30px;
    }
    .balance_group input::before {
        width: 16px;
        height: 16px;    
    }
    .successfully_popup img {
        width: 90px;
        height: 85px;
    }
    .successfully_popup {
        width: 340px;
        height: 395px;
        padding: 20px;
    }
    .successfully_popup a {
        margin: 30px auto 0px;
    }
    .notification_box {
        grid-gap: 16px;
    }
    .notification_content {
        width: calc(100% - 0px);
    }
    .trip_box {
        border-radius: 16px;
        padding: 18px 24px;
    }
    .trip_head {
        grid-gap: 16px 24px;
    }
    .country_charges {
        width: 100%;
        flex-flow: unset;
        align-items: center;
    }
    .tourism_box,.customer_grid {
        grid-gap: 16px;
        flex-flow: wrap;
    }
    .city_tour_box {
        padding: 16px;
    }
    .city_tour_grid .btn_group {
        margin-top: 24px;
    }
    .city_tour_box .term_heading:nth-child(2) {
        margin-top: 10px;
    }
    .city_tour_box .term_heading {
        margin-bottom:16px;
    }
    .proof_contact span {
        width: 40px;
        height: 40px;
    }
    form.varification_form {
        padding: 16px 20px;
    }
    .contact_active p strong {
        margin-bottom: 4px;
}
}