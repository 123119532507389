.glightbox-container .ginner-container {
    background: transparent;
    grid-template-columns: auto;
    max-width: 700px;
    margin: 0 auto;
    }
.glightbox-container {
width: 100%;
height: 100%;
position: fixed;
top: 0;
left: 0;
z-index: 999999 !important;
overflow: hidden;
-ms-touch-action: none;
touch-action: none;
-webkit-text-size-adjust: 100%;
-moz-text-size-adjust: 100%;
-ms-text-size-adjust: 100%;
text-size-adjust: 100%;
-webkit-backface-visibility: hidden;
backface-visibility: hidden;
outline: none;
}
.glightbox-container.inactive {
display: none;
}
.glightbox-container .gcontainer {
position: relative;
width: 100%;
height: 100%;
z-index: 9999;
overflow: hidden;
}
.glightbox-container .gslider {
-webkit-transition: -webkit-transform 0.4s ease;
transition: -webkit-transform 0.4s ease;
transition: transform 0.4s ease;
transition: transform 0.4s ease, -webkit-transform 0.4s ease;
height: 100%;
left: 0;
top: 0;
width: 100%;
position: relative;
overflow: hidden;
display: -webkit-box !important;
display: -ms-flexbox !important;
display: flex !important;
-webkit-box-pack: center;
-ms-flex-pack: center;
justify-content: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
-webkit-transform: translate3d(0, 0, 0);
transform: translate3d(0, 0, 0);
}
.glightbox-container .gslide {
width: 100%;
position: absolute;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
-webkit-box-pack: center;
-ms-flex-pack: center;
justify-content: center;
opacity: 0;
}
.glightbox-container .gslide.current {
opacity: 1;
z-index: 99999;
position: relative;
}
.glightbox-container .gslide.prev {
opacity: 1;
z-index: 9999;
}
.glightbox-container .ginner-container {
position: relative;
width: 100%;
}
.glightbox-container .ginner-container.gvideo-container {
width: 100%;
}
.glightbox-container .ginner-container.desc-bottom, .glightbox-container .ginner-container.desc-top {
-webkit-box-orient: vertical;
-webkit-box-direction: normal;
-ms-flex-direction: column;
flex-direction: column;
}
.glightbox-container .ginner-container.desc-left, .glightbox-container .ginner-container.desc-right {
max-width: 100% !important;
}
.gslide iframe, .gslide video {
outline: none !important;
border: none;
min-height: 165px;
-webkit-overflow-scrolling: touch;
-ms-touch-action: auto;
touch-action: auto;
}
.gslide:not(.current) {
pointer-events: none;
}
.gslide-image {
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
}
.gslide-image img {
max-height: 100vh;
display: block;
padding: 0;
float: none;
outline: none;
border: none;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
max-width: 100vw;
width: auto;
height: auto;
-o-object-fit: cover;
object-fit: cover;
-ms-touch-action: none;
touch-action: none;
margin: auto;
min-width: 200px;
}
.desc-top .gslide-image img, .desc-bottom .gslide-image img {
width: auto;
}
.desc-left .gslide-image img, .desc-right .gslide-image img {
width: auto;
max-width: 100%;
height: 100%;
}
.gslide-image img.zoomable {
position: relative;
}
.gslide-image img.dragging {
cursor: -webkit-grabbing !important;
cursor: grabbing !important;
-webkit-transition: none;
transition: none;
}
.gslide-video {
position: relative;
max-width: 100vh;
width: 100% !important;
}
.gslide-video .plyr__poster-enabled.plyr--loading .plyr__poster {
display: none;
}
.gslide-video .gvideo-wrapper {
width: 100%;
margin: auto;
}
.gslide-video::before {
content: '';
position: absolute;
width: 100%;
height: 100%;
background: rgba(255, 0, 0, 0.34);
display: none;
}
.gslide-video.playing::before {
display: none;
}
.gslide-video.fullscreen {
max-width: 100% !important;
min-width: 100%;
height: 75vh;
}
.gslide-video.fullscreen video {
max-width: 100% !important;
width: 100% !important;
}
.gslide-inline {
background: var(--white);
text-align: left;
max-height: calc(100vh - 40px);
overflow: auto;
max-width: 100%;
margin: auto;
}
.gslide-inline .ginlined-content {
padding: 20px;
width: 100%;
}
.gslide-inline .dragging {
cursor: -webkit-grabbing !important;
cursor: grabbing !important;
-webkit-transition: none;
transition: none;
}
.ginlined-content {
overflow: auto;
display: block !important;
opacity: 1;
}
.gslide-external {
display: -webkit-box;
display: -ms-flexbox;
display: flex;
width: 100%;
min-width: 100%;
background: var(--white);
padding: 0;
overflow: auto;
max-height: 75vh;
height: 100%;
}
.desc-top .gslide-media, .desc-bottom .gslide-media {
margin: 0 auto;
-webkit-box-orient: vertical;
-webkit-box-direction: normal;
-ms-flex-direction: column;
flex-direction: column;
}
.gslide-description {
position: relative;
-ms-flex: 1 0 100%;
}
.gslide-description.description-left, .gslide-description.description-right {
max-width: 100%;
}
.gslide-description.description-bottom, .gslide-description.description-top {
margin: 0 auto;
width: 100%;
}
.gslide-description p {
margin-bottom: 12px;
}
.gslide-description p:last-child {
margin-bottom: 0;
}
.zoomed .gslide-description {
display: none;
}
.glightbox-button-hidden {
display: none;
}
/* * Description for mobiles * something like facebook does the description * for the photos */
.glightbox-mobile .glightbox-container .gslide-description {
max-width: 100vw !important;
}
.glightbox-mobile .glightbox-container .gslide-title {
color: var(--white);
font-size: 1em;
}
.glightbox-mobile .glightbox-container .gslide-desc {
/* color: #a1a1a1; */
}
.gdesc-open .gdesc-inner {
padding-bottom: 30px;
}
.gdesc-closed .gslide-media {
-webkit-transition: opacity 0.5s ease;
transition: opacity 0.5s ease;
opacity: 1;
}
.greset {
-webkit-transition: all 0.3s ease;
transition: all 0.3s ease;
}
.gabsolute {
position: absolute;
}
.grelative {
position: relative;
}
.glightbox-desc {
display: none !important;
}
.glightbox-open {
overflow: hidden;
}
.gloader {
height: 25px;
width: 25px;
-webkit-animation: lightboxLoader 0.8s infinite linear;
animation: lightboxLoader 0.8s infinite linear;
border: 2px solid var(--white);
border-right-color: transparent;
border-radius: 50%;
position: absolute;
display: block;
z-index: 9999;
left: 0;
right: 0;
margin: 0 auto;
top: 47%;
}
.goverlay {
width: 100%;
height: calc(100vh + 1px);
position: fixed;
top: -1px;
left: 0;
background: #000;
will-change: opacity;
}
.glightbox-mobile .goverlay {
background: #000;
}
.gprev, .gnext, .gclose {
z-index: 99999;
cursor: pointer;
width: 26px;
height: 44px;
border: none;
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-pack: center;
-ms-flex-pack: center;
justify-content: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
-webkit-box-orient: vertical;
-webkit-box-direction: normal;
-ms-flex-direction: column;
flex-direction: column;
}
.gprev svg, .gnext svg, .gclose svg {
display: block;
width: 25px;
height: auto;
margin: 0;
padding: 0;
}
.gprev.disabled, .gnext.disabled, .gclose.disabled {
opacity: 0.1;
}
.gprev .garrow, .gnext .garrow, .gclose .garrow {
stroke: var(--white);
}
.gbtn.focused {
outline: 2px solid #0f3d81;
}
iframe.wait-autoplay {
opacity: 0;
}
.glightbox-closing .gnext, .glightbox-closing .gprev, .glightbox-closing .gclose {
opacity: 0 !important;
}
/*Skin */
.glightbox-clean .gslide-description {
background: var(--white);
}
.glightbox-clean .gdesc-inner {
padding: 30px;
}
.glightbox-clean .gslide-title {
font-size: 1em;
font-weight: normal;
font-family: arial;
color: #000;
margin-bottom: 19px;
line-height: 1.4em;
}
.glightbox-clean .gslide-video {
background: #000;
}
.glightbox-clean .gprev, .glightbox-clean .gnext, .glightbox-clean .gclose {
background-color: rgba(0, 0, 0, 0.75);
border-radius: 4px;
}
.glightbox-clean .gprev path, .glightbox-clean .gnext path, .glightbox-clean .gclose path {
fill: var(--white);
}
.glightbox-clean .gprev {
position: absolute;
top: -100%;
left: 30px;
width: 40px;
height: 50px;
}
.glightbox-clean .gnext {
position: absolute;
top: -100%;
right: 30px;
width: 40px;
height: 50px;
}
.glightbox-clean .gclose {
width: 35px;
height: 35px;
top: 15px;
right: 10px;
position: absolute;
}
.glightbox-clean .gclose svg {
width: 18px;
height: auto;
}
.glightbox-clean .gclose:hover {
opacity: 1;
}
/*CSS Animations*/
.gfadeIn {
-webkit-animation: gfadeIn 0.5s ease;
animation: gfadeIn 0.5s ease;
}
.gfadeOut {
-webkit-animation: gfadeOut 0.5s ease;
animation: gfadeOut 0.5s ease;
}
.gslideOutLeft {
-webkit-animation: gslideOutLeft 0.3s ease;
animation: gslideOutLeft 0.3s ease;
}
.gslideInLeft {
-webkit-animation: gslideInLeft 0.3s ease;
animation: gslideInLeft 0.3s ease;
}
.gslideOutRight {
-webkit-animation: gslideOutRight 0.3s ease;
animation: gslideOutRight 0.3s ease;
}
.gslideInRight {
-webkit-animation: gslideInRight 0.3s ease;
animation: gslideInRight 0.3s ease;
}
.gzoomIn {
-webkit-animation: gzoomIn 0.5s ease;
animation: gzoomIn 0.5s ease;
}
.gzoomOut {
-webkit-animation: gzoomOut 0.5s ease;
animation: gzoomOut 0.5s ease;
}
@-webkit-keyframes lightboxLoader {
0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}
100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
}
}
@keyframes lightboxLoader {
0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}
100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
}
}
@-webkit-keyframes gfadeIn {
from {
    opacity: 0;
}
to {
    opacity: 1;
}
}
@keyframes gfadeIn {
from {
    opacity: 0;
}
to {
    opacity: 1;
}
}
@-webkit-keyframes gfadeOut {
from {
    opacity: 1;
}
to {
    opacity: 0;
}
}
@keyframes gfadeOut {
from {
    opacity: 1;
}
to {
    opacity: 0;
}
}
@-webkit-keyframes gslideInLeft {
from {
    opacity: 0;
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0);
}
to {
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
}
}
@keyframes gslideInLeft {
from {
    opacity: 0;
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0);
}
to {
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
}
}
@-webkit-keyframes gslideOutLeft {
from {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
to {
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0);
    opacity: 0;
    visibility: hidden;
}
}
@keyframes gslideOutLeft {
from {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
to {
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0);
    opacity: 0;
    visibility: hidden;
}
}
@-webkit-keyframes gslideInRight {
from {
    opacity: 0;
    visibility: visible;
    -webkit-transform: translate3d(60%, 0, 0);
    transform: translate3d(60%, 0, 0);
}
to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
}
}
@keyframes gslideInRight {
from {
    opacity: 0;
    visibility: visible;
    -webkit-transform: translate3d(60%, 0, 0);
    transform: translate3d(60%, 0, 0);
}
to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
}
}
@-webkit-keyframes gslideOutRight {
from {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
to {
    -webkit-transform: translate3d(60%, 0, 0);
    transform: translate3d(60%, 0, 0);
    opacity: 0;
}
}
@keyframes gslideOutRight {
from {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
to {
    -webkit-transform: translate3d(60%, 0, 0);
    transform: translate3d(60%, 0, 0);
    opacity: 0;
}
}
@-webkit-keyframes gzoomIn {
from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
}
to {
    opacity: 1;
}
}
@keyframes gzoomIn {
from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
}
to {
    opacity: 1;
}
}
@-webkit-keyframes gzoomOut {
from {
    opacity: 1;
}
50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
}
to {
    opacity: 0;
}
}
@keyframes gzoomOut {
from {
    opacity: 1;
}
50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
}
to {
    opacity: 0;
}
}
@media (min-width: 769px) {
.glightbox-container .ginner-container {
    height: auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}
.glightbox-container .ginner-container.desc-top .gslide-description {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
}
.glightbox-container .ginner-container.desc-top .gslide-image, .glightbox-container .ginner-container.desc-top .gslide-image img {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
}
.glightbox-container .ginner-container.desc-left .gslide-description {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
}
.glightbox-container .ginner-container.desc-left .gslide-image {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
}
.gslide-image img {
    max-width: 100%;
    margin: 0px;
    height: 100%;
    display: block;
    width: 100%;
}
.gslide-image img.zoomable {
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
}
.zoomed .gslide-image img.zoomable {
    cursor: -webkit-grab;
    cursor: grab;
}
.gslide-inline {
    max-height: 95vh;
}
.gslide-external {
    max-height: 100vh;
}
.glightbox-open {
    height: auto;
}
.goverlay {
    background: rgba(0, 0, 0, 0.92);
}
.glightbox-clean .description-left .gdesc-inner, .glightbox-clean .description-right .gdesc-inner {
   height: 100%;
   overflow-y: auto;
   width: 100%;
}
.glightbox-clean .gprev, .glightbox-clean .gnext, .glightbox-clean .gclose {
    background-color: rgba(0, 0, 0, 0.32);
}
.glightbox-clean .gprev:hover, .glightbox-clean .gnext:hover, .glightbox-clean .gclose:hover {
    background-color: rgba(0, 0, 0, 0.7);
}
.glightbox-clean .gprev {
    top: 45%;
}
.glightbox-clean .gnext {
    top: 45%;
}
}
@media (min-width: 992px) {
.glightbox-clean .gclose {
    opacity: 0.7;
    right: 20px;
}
}
@media screen and (max-height: 420px) {
.goverlay {
    background: #000;
}
}
.discription_list{
display: none;
}
.box a {
display: block;
width: 100%;
height: auto;
position: relative;
overflow: hidden;
}
.portfolio-container .box .portfolio-info {
opacity: 0;
position: absolute;
left: 0px;
right: 0px;
bottom: 0%;
z-index: 3;
transition: all ease-in-out 0.3s;
background: rgba(61, 160, 216, 90%);
padding: 24px;
transition: 0.5s;
display: flex;
height: 100%;
display: flex;
align-items: end;
}
.portfolio-container .box:hover .portfolio-info {
opacity: 1;
}
.portfolio-container .portfolio-info h4, .portfolio-container .portfolio-info p {
color: var(--white);
margin: 0;
margin: 0;
}
.box a::before{
background: transparent !important;
}
.box:hover img {
transform: translate3d(0, 0, 0);
transition: opacity 0.35s, transform 0.35s;
transform: scale(1.1);
}
.portfolio-container {
display: grid;
justify-content: center;
width: 100%;
}
.portfolio-container > div {
padding: 10px;
width: 25%;
}
.portfolio-container .box .inner>a{
display: flex;
align-items: center;
justify-content: center;
}
.portfolio-container .box img{
width:268px;
transform: translate3d(0px, 0, 0);
}
.portfolio-container .box .inner {
padding: 0 !important;
width: 100%;
}
.portfolio-container .box .inner a, .portfolio-container .box .inner a > img {
height: 100%;
width: 100%;
border-radius: 8px;
display: block;
object-fit: cover;
}
.portfolio-container .box .inner a, .portfolio-container .box .inner a > img{
min-height: 330px;
} 
.brand_img img{
max-width: 100%;
}
.brand_details p {
font-size: 12px;
line-height: 20px;
}
.brand_details {
padding:15px;
}
.our_brand_right {
border: 1px solid #d0cbcb;
}
.brand_details h3{
font-size: 20px;
color: #000;
font-weight: 600;
margin: 15px 0;
}
.portfolio-container .box {
width: 100% !important;
margin-bottom:0;
box-shadow: 0px 4px 40px 0px transparent;
transition: all 0.3s;
border-radius: 5px;
}
.portfolio-container .box .inner img {
transition: 0.5s;
}
.glightportfolio-container .ginner-container.desc-right {
min-height: 500px;
}
.portfolio-container .box img {
max-width:100%;
}
.gslide:not(.current) {
display: none;
}
.glightbox-container .ginner-container.desc-right {
min-height: 500px;
}
.portfolio_list ul{
padding: 0px;
margin: 0px 0px 50px 0px;
display: flex;
flex-flow: wrap;
justify-content: center;
grid-gap: 24px;
}
.portfolio_list ul li {
cursor: pointer;
display: inline-block;
font-size: 16px;
font-weight: 700;
line-height: 24px;
transition: all ease-in-out 0.3s;
padding: 11px 35px;
color: var(--blue);
letter-spacing: 0.5px;
text-transform: capitalize;
border: 2px solid #F2F2F2;
min-width: fit-content;
border-radius: 4px;
text-align: center;
}
.portfolio_list ul li:hover, .portfolio_list ul li.filter-active {
color: var(--white);
background:var(--sky_blue);
border-color: var(--sky_blue);
}
.glightbox-container .gslide-inner-content {
width: calc(100% - 165px);
max-width: 60%;
}
.glightbox-container .ginner-container.desc-right > div {
width: 100% !important;
max-width: 100% !important;
}
.dis_title h2,.gslide-desc {
color: var( --black);
font-size: 24px;
font-weight: 700;
text-transform: capitalize;
line-height: 38px;
margin: 0px 0px 15px 0px;
} 
.gslide-desc {
margin-bottom: 0px;
}
.gslide-desc a {
font-size: 16px;
display: block;
color: var(--sky_blue);
font-weight: 600;
}
.dis_title p{
text-align: left;
color:var(--grey);
margin: 0px;
font-size: 16px;
font-weight: 500;
line-height: 26px;
}
@media(max-width: 1280px){
.glightbox-container .gslide-inner-content {
 max-width: 980px;
}
.portfolio-container .box .inner a, .portfolio-container .box .inner a > img{
   min-height: 300px;
} 
}
@media(max-width: 991px){
.portfolio-container > div {
   width:33.33%;
}
.portfolio_list ul li {
   font-size: 16px;
   margin: 0px 0px 0px 0px;
   padding: 12px 16px;
}
.portfolio_list ul {
   margin: 0px 0px 30px 0px;
   grid-gap: 16px;
}
.glightbox-container .ginner-container {
 grid-template-columns:repeat(1,1fr);
}
.glightbox-container .ginner-container.desc-left, .glightbox-container .ginner-container.desc-right {
   max-height: 80vh;
   overflow: auto;
}
.gslide-media.gslide-image {
   max-height: 405px;
}
.portfolio-container .box .inner a, .portfolio-container .box .inner a > img{
   min-height: 280px;
} 
}
@media(max-width: 767px){
.glightbox-clean .gdesc-inner {
 padding: 20px !important;
}
.dis_title h2, .gslide-desc {
 font-size: 20px;
 line-height: 28px;
}
.glightbox-container .gslide-inner-content {
 width: calc(100% - 40px);
}
.portfolio_list ul{
   margin-bottom: 40px;
}
}
@media(max-width: 700px){
.portfolio-container > div {
   width:50%;
}
.portfolio_list ul li {
   padding: 10px 30px;
   letter-spacing: 0px;
}
.portfolio_list ul{
   margin-bottom: 24px;
   grid-gap: 16px;
}
}
@media(max-width: 575px){
.dis_title h2, .gslide-desc {
   margin: 0px 0px 10px 0px;
}
.portfolio-container .box .inner a, .portfolio-container .box .inner a > img{
   min-height: 240px;
} 
}
/*---------------|| Tabs End  ||----------------*/